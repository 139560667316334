// TABLET < 1200
@media only screen and (max-width: $size-medium-large-1){
  .page-catalog {

    a#clear-filters {
      float: none;
      margin: auto;
      @include x-rem('margin-bottom', 10px);
    }

    .sortby {
      text-align: center;
    }

    .view-web-product-catalog {
      .products-container {
        display: block;

        .product-left-filter {
          flex: auto;
          margin-right: 0;
          @include x-rem('margin-bottom', 15px);
          max-width: none;
          padding: 0;
          margin-left: 0;

          .views-filters {
            .views-exposed-form {
              .views-exposed-widgets {
                .views-exposed-widget {
                  & > label {
                    //background: $blue;
                    //color: $white;
                    //text-align: center;
                    //font-weight: 500;
                    //@include x-rem('padding', 10px 0);
                    border-radius: 20px;
                  }

                  .views-widget {
                    display: none;
                    @include x-rem('margin', 15px 0);
                    @include x-rem('padding', 0 10px);
                  }
                }
              }
            }

            &.not-empty {
              .views-exposed-form {
                .views-exposed-widgets {
                  .views-exposed-widget {
                    .views-widget {
                      display: block;
                    }
                  }
                }
              }
            }
          }
        }

        .product-list {
          .view-content {
            .views-row {
              flex: 0 0 calc(50% - 7.5px);
              max-width: calc(50% - 7.5px);

              // All 3 elements starting from 2.
              &:nth-child(3n+2) {
                margin-left: 0;
                margin-right: 0;
              }

              // Each 2 elements starting from one.
              &:nth-child(2n+1) {
                @include x-rem('margin-left', 15px);
              }

              // 3 first elements.
              &:nth-child(-n+3) {
                margin-top: 15px;
              }

              // 2 first elements.
              &:nth-child(-n+2) {
                margin-top: 0;
              }
            }
          }
        }
      }
    }
  }
}


// MOBILE < 490
@media only screen and (max-width: $size-medium-1){
  .page-catalog {
    .view-web-product-catalog {
      display: block;

      .product-left-filter {
        flex: auto;
        margin-right: 0;
        @include x-rem('margin-bottom', 15px);
      }

      .more-details {
        a {
          @include x-rem('font-size', 15px);
          padding: 5px 20px;
        }
      }
    }
  }
}
