.login-container {
  max-width: 1200px;
  margin: auto;
  @include box-shadow-content();

  #user-login {
    padding: 30px;
    width: 40%;
    margin: 85px auto 0;
    text-align: center;

    h2 {
      margin: 0 0 30px;
    }

    .login-forgot {
      a {
        @include x-rem('font-size', 16px);
        font-style: italic;
      }
    }
  }
}



.page-user {
  .ess-user-login {
    display: flex;
    justify-content: space-between;
    margin: 100px 0 0;

    & > div {
      flex: 1 1 0;
      @include box-shadow-content();
      background: $white;
      text-align: center;

      h2 {
        margin: 5px 0 20px;
      }

      &#user-login-text-block {
        margin-left: 20px;

        .ss_login_intro {
          p {
            font-weight: 500;
          }
        }

        ul {
          margin: 0;
          padding: 0;

          li {
            margin: 0;
            position: relative;
            font-weight: bold;
            margin-bottom: 30px;

            span {
              display: block;
              height: 45px;
              line-height: 68px;
              font-weight: bold;

              &::before {
                content: ' ';
                display: block;
                position: absolute;
                left: 0;
                right: 0;
                top: 16.5px;
                margin: auto;
                border: 2px solid $blue_light;
                width: 35px;
                height: 35px;
                border-top-left-radius: 0;
                border-top-right-radius: 50%;
                border-bottom-right-radius: 50%;
                border-bottom-left-radius: 50%;
                transform: rotate(45deg);
              }
            }
          }
        }
      }

      &#login-form,
      &#login-otp-form {
        form {
          width: 75%;
          margin: auto;

          .form-actions {
            margin: 0;
          }
        }

        ul.quick-links {
          li {
            margin: 0;

            &.first {
              a {
                @include x-rem('font-size', 16px);
                font-style: italic;

                &:hover {
                  text-decoration: underline;
                }
              }
            }

            &.last {
              border-top: 1px solid #f1f1f1;
              margin-top: 15px;
              padding-top: 10px;

              a {
                display: inline-block;
                margin-top: 15px;
                padding: 8px 25px;
                border-radius: 25px;
                @include x-rem('font-size', 14px);
                text-transform: none;
                background-color: $blue_light;
                color: $white;
                text-decoration: none;
              }
            }
          }
        }
      }
    }
    #edensprings-self-service-user-login-otp {
      @include x-rem('padding', 20px);
      .phone-container {
        display: flex;
        flex-direction: row-reverse;
        .form-item-phone-prefix {
          flex: 1 1 60%;
          margin-right: 15px;
        }
      }
    }

    &.multi-login {
      & > .form-container {
        ul.tab {
          //margin: 0 130px 30px;
          padding: 0;
          display: flex;
          justify-content: space-between;
          margin: 25px auto;
          width: 50%;

          li {
            flex: 0 0 49%;
            margin: 0;
            padding: 10px 5px 12px;
            display: inline-block;
            position: relative;
            text-align: center;
            cursor: pointer;
            border-radius: 30px;
            background-color: rgba(37, 45, 92, 0.25);
            color: $white;
            font-weight: 500;
            letter-spacing: 1.3px;
            transition: all 0.5s ease-out;

            i, span {
              vertical-align: middle;
            }

            span {
              margin-left: 5px;
            }

            &.active {
              background-color: $blue;
              cursor: auto;

              &:after {
                content: '';
                width: 0;
                height: 0;
                bottom: -8px;
                position: absolute;
                left: calc(50% - 5px);
                border-left: 10px solid transparent;
                border-right: 10px solid transparent;
                border-top: 10px solid $blue;
              }
            }

            &:hover {
              background-color: $blue;
            }
            &:first-child {
              margin-left: 2%;
            }
          }
        }

        form {
          display: none;
          width: 75%;
          margin: auto;
          .form-actions {
            margin: 0;
          }

          &.active {
            display: block;
          }
        }
        ul.quick-links {
          li {
            margin: 0;

            &.first {
              a {
                @include x-rem('font-size', 16px);
                font-style: italic;

                &:hover {
                  text-decoration: underline;
                }
              }
            }

            &.last {
              border-top: 1px solid #f1f1f1;
              margin-top: 15px;
              padding-top: 10px;

              a {
                display: inline-block;
                margin-top: 15px;
                padding: 8px 25px;
                border-radius: 25px;
                @include x-rem('font-size', 14px);
                text-transform: none;
                background-color: $blue_light;
                color: $white;
                text-decoration: none;
              }
            }
          }
        }

        #edensprings-self-service-user-login-otp {
          @include x-rem('padding', 20px);
          .phone-container {
            display: flex;
            flex-direction: row-reverse;
            .form-item-phone-prefix {
              flex: 1 1 60%;
              margin-right: 15px;
            }
          }
        }
      }
    }
  }


  .ss-user-register {
    margin-top: 100px;

    h2 {
      text-align: center;
      margin: 10px 0 20px;
    }

    form {
      text-align: center;
      width: 50%;
      margin: auto;
      padding-bottom: 30px;

      .form-item {
        padding: 3px 0;

        label {
          display: none;
        }

        div.description {
          text-align: right;
          background: url(../images/help.png) no-repeat right center;
          padding-right: 20px;
          margin: 3px 0 0;
          color: #06A7E0;

          &.password-suggestions {
            width: auto;
            padding: 5px 20px 5px 10px;
            border: 0;
            @include x-rem('font-size', 14px);
          }
        }

        .password-strength {
          text-align: right;

          .password-strength-title {
            margin-right: 5px;
          }
        }

        .password-confirm {
          width: 100%;
          text-align: right;
          margin-bottom: 15px;
        }

        //.password-suggestions {
        //  display: none !important;
        //}
      }

      .term_cond {
        text-align: center;
        margin: 25px 0 15px;
        font-weight: 500;
      }

      .term-cond-container {
        position: relative;
        padding: 30px 10px;
        background: $blue_medium;
        margin-bottom: 30px;

        .terms_checkbox_descr {
          height: 90px;
          overflow: auto;
          text-align: left;

          p {
            @include x-rem('font-size', 12px);
          }
        }

        &:before {
          content: '';
          width: 0;
          height: 0;
          border-left: 20px solid transparent;
          border-right: 20px solid transparent;
          border-top: 20px solid $white;
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          margin: auto;
        }
      }

      .captcha {
        margin-bottom: 30px;

        .text-xs-center {
          text-align: center;
        }

        .g-recaptcha {
          display: inline-block;
        }
      }

      input[type="submit"] {
        @include x-rem('font-size', 14px);
        padding: 14px 50px;
        font-weight: 500;
      }
    }

    &.multi-registration {
      ul.tab {
        //margin: 0 130px 30px;
        padding: 0;
        display: flex;
        justify-content: space-between;
        margin: 25px auto;
        width: 50%;

        li {
          flex: 0 0 49%;
          margin: 0;
          padding: 10px 5px 12px;
          display: inline-block;
          position: relative;
          text-align: center;
          cursor: pointer;
          border-radius: 30px;
          background-color: rgba(37, 45, 92, 0.25);
          color: $white;
          font-weight: 500;
          letter-spacing: 1.3px;
          transition: all 0.5s ease-out;

          i, span {
            vertical-align: middle;
          }

          span {
            margin-left: 5px;
          }

          &.active {
            background-color: $blue;
            cursor: auto;

            &:after {
              content: '';
              width: 0;
              height: 0;
              bottom: -8px;
              position: absolute;
              left: calc(50% - 5px);
              border-left: 10px solid transparent;
              border-right: 10px solid transparent;
              border-top: 10px solid $blue;
            }
          }

          &:hover {
            background-color: $blue;
          }
          &:first-child {
            margin-gulp: 2%;
          }
        }
      }
      form {
        display: none;
        &.active {
          display: block;
        }
      }
    }


    #edensprings-self-service-user-register-by-cc {
      .phone-container {
        display: flex;
        flex-direction: row-reverse;
        .form-item-phone-prefix {
          flex: 1 1 33%;
          margin-right: 15px;
        }
      }
    }

  }

  .ss-user-pass {
    margin-top: 100px;

    form {
      width: 50%;
      margin: auto;
      text-align: center;

      label {
        margin-bottom: 5px;
      }
    }

    .up-message {
      @include x-rem('font-size', 14px);
    }
  }

}
