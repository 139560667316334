// MOBILE < 768
@media only screen and (max-width: $size-medium-1){
  footer {
    .region-footer {
      padding: 0;
    }

    .curved {
      height: 25px;
    }

    #block-edensprings-website-ng-blocks-website-ng-v3-footer {
      .footer-top {
        display: block;
        text-align: center;

        ul.footer-certifications {
          padding: 0;
          margin-bottom: 10px;
        }

        .separator {
          display: none;
        }

        ul.footer-social {
          padding: 15px 0 5px 0;          
          margin-left: 0;

          &:before {
            display: none;
          }
        }
      }

      .footer-bottom {
        display: block;
        text-align: center;

        .menu-container {
          //display: block;
          //justify-content: space-between;
          margin-top: 15px;

          .group-links {
            max-width: 50%;
            min-width: 50%;
            margin: 0;
            margin-bottom: 10px;


            .group-title,
            ul li {
              text-align: center;
              a {
                max-width: 50%;
                min-width: 50%;
              }
            }

            ul {
              display: none;
            }
          }
        }
      }


      .feedbacks {
        &.reviews iframe {
          height:320px;
        }
      }
    }
  }

  // COOKIE BANNER.
  #sliding-popup {
    padding: 0 10px;
  }
}
