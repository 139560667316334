.node-type-wngv-web-product {
  .top-product {
    box-shadow: 0 4px 4px -4px $grey_disabled;

    .sse-product-img-variant-addr {
      display: flex;
      justify-content: normal;
      overflow: hidden;

      .sse-product-img {
        overflow: hidden;
        flex: 0 0 400px;
        max-width: 400px; // HACK IE11
        @include x-rem("margin-left", 60px);

        .slick-list {
          //border-radius: 30px;
        }

        .slick-slide {
          text-align: center;
          figure {
            border-radius: 30px;
            border: 2px solid $il_blue;
            @include box-shadow-content();
            overflow: hidden;
            img {
              display: inline-block;
              overflow: hidden;
              transition: all 0.2s ease;
              vertical-align: middle;
              &:hover {
                transform: scale(1.25);
                -ms-transform: scale(1.25); /* IE 9 */
                -moz-transform: scale(1.25); /* Firefox */
                -webkit-transform: scale(1.25); /* Safari and Chrome */
                -o-transform: scale(1.25); /* Opera */
              }
            }
          }
        }

        .slider-nav-thumbnails {
          display: none;
          margin: 15px 0 0;
        }

        .slick-dots {
          display: flex !important;
          justify-content: center;
          @include x-rem("margin", 15px 0);
          padding: 0;
          text-align: center;

          li[role="presentation"] {
            @include x-rem("margin-right", 5px);
            display: inline-block;
            opacity: 0.5;
            border: 2px solid $grey_disabled;
            border-radius: 8px;

            //&:last-child {
            //  margin: 0;
            //}

            a,
            img {
              display: block;
              cursor: pointer;
            }

            img {
              border-radius: 7px;
            }

            &.slick-active {
              opacity: 1;
              border: 2px solid $il_blue;
              border-radius: 8px;
            }
          }
        }
      }
      .sse-product-infos {
        flex: 1;
      }
      .introduction {
        h1 {
          padding-bottom: 10px;
        }

        // Body.
        .field-name-body {
          p {
            @include font-style("FbSpacer", 800);
            @include x-rem("font-size", 20px);
            line-height: 22px;
            color: $il_blue;
          }
        }
      }
    }

    .sse-product-texts {
      overflow: hidden;

      .product-features {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        justify-content: center;
        text-align: center;
        //width: 70%;
        margin: 0 auto 50px;

        & > div.product-feature {
          //max-width: 100px;
          margin: 0 15px;

          svg {
            display: block;
            margin: 0 auto;
            fill: $il_blue;
          }

          span {
            @include x-rem("font-size", 18px);
            color: $il_blue;
            line-height: 16px;
          }
        }
      }
      .video-container {
        width: 70%;
        max-height: 400px;
        margin: 20px 0;
        iframe {
          max-height: 400px;
        }
      }

      .field-collection-container {
        margin: 0;
        border: none;

        .field-name-field-pp-characteristic-list {
          & > .field-items {
            display: flex;
            justify-content: center;

            & > .field-item {
              @include x-rem("margin-bottom", 30px);
              flex: 1 1 50%;

              .field-collection-view {
                margin: 0;
                padding: 0;
                border: none;

                .field-name-field-ppcl-list-item {
                  @include x-rem("margin-left", 20px);

                  .field-item {
                    @include x-rem("font-size", 18px);
                    color: $blue;

                    &:before {
                      content: "";
                      display: inline-block;
                      width: 6px;
                      height: 6px;
                      background: $blue;
                      border-radius: 50%;
                      @include x-rem("font-size", 14px);
                      line-height: 14px;
                      @include x-rem("margin", 0 8px 2px 8px);
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .sse-product-related {
    @include x-rem("padding", 40px 0 20px);

    h3 {
      text-align: center;
      color: $blue;
      font-weight: bold;
      @include x-rem("font-size", 35px);
      @include x-rem("margin-bottom", 50px);
      margin-top: 0;
    }

    .field-name-field-web-related-product {
      & > .field-items {
        display: flex;
        justify-content: center;

        & > .field-item {
          flex: 0 0 calc(25% - 10px);
          max-width: calc(25% - 10px); // HACK IE11
          @include x-rem("margin-left", 15px);

          &:last-child {
            margin: 0;
          }
        }
      }
    }
  }
}

// PRODUCT PAGE TEASER
article.node-wngv-web-product {
  position: relative;
  border: solid 2px $il_blue;
  border-radius: 20px;
  //width: 80%;
  box-shadow: 0 0 20px -12px #000;
  padding: 0;
  overflow: hidden;

  &.node-teaser {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    .field-type-image {
      .field-item {
        a {
          display: block;
          overflow: hidden;

          img {
            display: block;
            border-radius: 0px;
            transition: all 0.2s ease;
            &:hover {
              transform: scale(1.1);
              opacity: 1;
            }
          }
        }
      }
    }

    h4 {
      @include x-rem("font-size", 19px);
      @include x-rem("letter-spacing", 2px);
      @include x-rem("line-height", 23px);
      @include x-rem("margin", 5px 0 20px);
      color: $il_blue;

      a {
        color: $blue;

        &:hover {
          color: $il_blue;
        }
      }
    }

    div.more-details {
      position: relative;
      bottom: 0;
      left: 0;
      right: 0;
      a {
        display: flex;
        align-items: center;
        text-align: center;
        justify-content: center;
        height: 58px;
        margin: 0;
        max-width: 100%;
        width: 100%;
        border-radius: 0;
      }
    }
  }
}
// END OF PRODUCT TEASER
