// MOBILE < 768
@media only screen and (max-width: $size-medium-1){

  .node-type-ingv-contact-us {

    .drops-no-slider {
      margin: 0;
    }


    .mobile-link {
      background: #252D5C;
      padding: 15px;
      border-radius: 20px;
      a {
        color: $white;
      }
    }
  }

}


// Mobile < 490
@media only screen and (max-width: $size-small-1){

  .node-type-ingv-contact-us {

    .drops-no-slider {
      .titles {

        .item {
          svg {
            width: 102px;
            height: 139px;
          }
          span {
            top: 65px;
            //width: 85px;
            width: 70%;
            padding: 0;
            @include x-rem('font-size', 15px);
            line-height: 18px;
          }
        }

        .item.active {
          svg {
          }
          span {
          }
        }
      }


      .descriptions {

        .item {
          p {
            @include x-rem('font-size', 18px);
          }
        }
      }
    }

    .infos {
      margin-top: 50px;
      .container {
        display: block;
        text-align: center;

        .col-infos {
          width: 100%;
          margin: 0;
        }

        .sms {
          border-radius: 20px;
          box-shadow: 0 0 20px -12px #000;
          padding: 5px;
          margin-bottom: 50px;
        }
        .whatsapp {
          margin-bottom: 50px;
          border-radius: 20px;
          box-shadow: 0 0 20px -12px #000;
          padding: 5px;
        }
        .technical-service {
          border-radius: 20px;
          box-shadow: 0 0 20px -12px #000;
          padding: 5px;
          margin-right: 0;
          h2 {

          }
          .form-actions {
            text-align: center;
          }
        }
      }
    }
  }
}