// TABLET < 1024
@media only screen and (max-width: $size-medium-large-1){
  .node-type-wngv-web-product {
    .top-product {
      padding: 0;
      .sse-product-img-variant-addr {
        margin-bottom: 0;
        padding-bottom: 0;
        box-shadow: none;
      }
      .sse-product-texts {
        .product-features {
          width: 100%;
        }
      }
    }

    .sse-product-related {
      @include x-rem('padding-left', 10px);
      @include x-rem('padding-right', 10px);

      .field-name-field-web-related-product {
        & > .field-items {
          flex-wrap: wrap;
          justify-content: flex-start;

          & > .field-item {
            flex: 0 0 calc(50% - 5px);
            max-width: calc(50% - 5px); // HACK IE11
            @include x-rem('margin-left', 10px);
            @include x-rem('margin-bottom', 10px);

            .btn {
              @include x-rem('font-size', 14px);
              padding: 8px 15px;
              line-height: 16px;
            }

            &:last-child {
              @include x-rem('margin-left', 10px);
              @include x-rem('margin-bottom', 10px);
            }

            &:nth-child(2n) {
              margin-left: 0;
            }
          }
        }
      }
    }
  }
}

// MOBILE < 490
@media only screen and (max-width: $size-medium-1){
  .node-type-wngv-web-product {
    .top-product {
      .sse-product-img-variant-addr {
        display: block;

        .sse-product-infos {
          .sse-product-texts {
            .field-name-field-pp-characteristic-list {
              .field-items {
                display: block;
              }
            }
          }
        }

        .sse-product-img {
          margin: 0 auto;
        }
      }
    }
  }

  div.node-wngv-web-product {
    &.node-teaser {
      h1 {
        @include x-rem('font-size', 14px);
        line-height: 16px;
      }

      div.more-details {
        .btn {
          @include x-rem('padding', 12px 20px);
          @include x-rem('font-size', 11px);
        }
      }
    }
  }

}

