// TABLET < 1200
@media only screen and (max-width: $size-large-1){
  header {
    #block-edensprings-israel-blocks-israel-header {
      #header-content {
        .wrapper {
          .left {
            .menu {
              ul {
                align-items: center;
                li {
                  a {
                    @include x-rem('font-size', 20px); //@todo change if new font (fbspacer photoshop => 22px)
                    line-height: 20px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

// TABLET < 1024
@media only screen and (max-width: $size-medium-large-1){
  header {
    #block-edensprings-israel-blocks-israel-header {
      #header-content {
        .wrapper {
          .right {
            flex: 0 0 140px;
            margin-left: 25px;

            a.home-link {
              right: 0;
            }
          }

          .left {
            .menu {
              ul {
                align-items: center;
                li {
                  text-align: center;

                  &.myeden {
                    span {
                      display: none;
                    }
                  }
                  &:not(:last-child) {
                    border-left: 0;
                  }

                  a {
                    @include x-rem('font-size', 22px);
                    line-height: 22px;
                  }
                }
              }
            }
          }
          //.top-header {
          //  a.btn-top {
          //    padding: 10px 5px 8px;
          //  }
          //}
        }
      }
    }
  }
}

// MOBILE < 768
@media only screen and (max-width: $size-medium-1) {
  header {
    .region-header-top {
      border: none;
    }

    #block-edensprings-israel-blocks-israel-header {
      #header-content {
        .wrapper {
          display: block;

          .right {
            display: flex;
            justify-content: center;
            margin: auto;
            align-items: center;
            padding-top: 2.5px;
            padding-bottom: 2.5px;

            a.home-link {
              flex: 1;
              position: static;
            }

            a.myeden {
              text-align: left;
              margin-right: auto;
              flex: 1;

              span {
                font-weight: bold;
                display: block;
                color: $blue;
              }

              i {
                padding-left: 30px;
                font-size: 25px;
                width: 25px;
                color: $blue;
              }
            }

            .menu-mobile {
              flex: 1;
              margin-left: auto;
              &.is-active {
                .burger-icon {
                  background-color: transparent;

                  &::before {
                    transform: translateY(8px) rotate(45deg);
                  }

                  &::after {
                    transform: translateY(-8px) rotate(-45deg);
                  }
                }
              }
            }

            .burger-icon {
              position: relative;
              margin-top: 8px;
              margin-bottom: 8px;
            }

            .burger-icon,
            .burger-icon::before,
            .burger-icon::after {
              display: block;
              @include x-rem('width', 25px);
              @include x-rem('height', 3px);
              background-color: $blue;
              outline: transparent solid 1px;
              transition: background-color .3s ease, transform .3s ease;
            }

            .burger-icon::before,
            .burger-icon::after {
              position: absolute;
              content: "";
            }

            .burger-icon::before {
              top: -8px;
            }

            .burger-icon::after {
              top: 8px;
            }
          }

          .left {
            .top-header {
              flex-direction: row;
              .phone {
                margin-left: 0;
                margin-bottom: 0;
                border-radius: 0;
                flex: 0 0 50%;

                i {
                  @include x-rem('font-size', 22px);
                }

                .phone-number {
                  a {
                    line-height: 20px;
                    @include x-rem('font-size', 22px);
                    font-weight: bold;
                  }
                }
              }


              a.btn-top {
                @include font-style('FbSpacer', 'normal');
                @include x-rem('font-size', 20px); //@todo change if new font (fbspacer photoshop => 20px)
                flex: 0 0 50%;
                border-radius: 0;

                &.get-an-offer {
                  flex: 1;
                }

                &.subscribe-online {
                  margin: 0;
                }
              }
            }

            .menu {
              display: none;
              margin: 0;
              position: absolute;
              top: 80px;
              background: $blue;
              width: 100%;
              height: calc(100% - 80px);
              z-index: 9999;
              overflow-y: auto;
              left: 0;

              & > ul {
                display: block;

                & > li {
                  margin: 0 20px;
                  display: block;
                  padding: 15px 0;
                  border-bottom: 1px solid rgba(255, 255, 255, 0.2);

                  & > span,
                  a {
                    color: $white;
                    display: block;
                    text-align: right;

                    &:hover, &:active {
                      color: $white;
                    }
                  }

                  &:after {
                    display: none;
                  }

                  &:first-child {
                    border-top: 1px solid rgba(255,255,255,0.2);
                  }

                  &.myeden {
                    span {
                      display: inline-block;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
