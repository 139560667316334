header {
  background: $grey_light;

  .region-header-top {
    //border-bottom: 3px solid #f1f1f1;
  }

  #block-edensprings-israel-blocks-israel-header {
    #header-content {
      .wrapper {
        display: flex;

        .right {
          flex: 0 0 182px;
          margin-left: 50px;

          .logo-sticky {
            display: none;
          }

          a.home-link {
            display: block;
            img {
              display: block;
            }
          }
        }

        .left {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          flex: 1 0 0;

          .top-header {
            display: flex;
            justify-content: flex-end;

            .phone {
              display: flex;
              align-items: center;
              color: $white;
              background-color: $blue;
              margin-left: 15px;
              padding: 10px 5px 11px;
              border-radius: 0 0 15px 15px;
              flex: 0 0 180px;
              font-weight: 500;
              justify-content: center;

              .phone-text {
                display: inline-block;
              }

              a {
                color: $white;
              }

              i {
                vertical-align: top;
                margin-left: 5px;
                @include x-rem('font-size', 22px);
              }

              span {
                display: block;
                text-align: center;
              }


              .phone-number {
                a {
                  @include x-rem('font-size', 22px);
                  font-weight: bold;
                  display: inline-block;

                  span {
                    display: inline-block;
                    vertical-align: sub;
                  }
                }
              }
            }


            a.btn-top {
              @include font-style('FbSpacer', 800);
              @include x-rem('font-size', 22px); //@todo change if new font (fbspacer photoshop => 24px)
              display: inline-block;
              padding: 10px 5px 11px;
              border-radius: 0 0 15px 15px;
              flex: 0 0 180px;
              text-align: center;
              font-weight: 500;

              br {
                display: none;
              }

              &.subscribe-online {
                background-color: $orange_cta;
                color: white;
                margin-right: 15px;
              }

              &.get-an-offer {
                background-color: $orange_cta;
                color: $white;
              }
            }
          }


          .menu {
            margin: 0 0 15px;

            ul {
              margin: 0;
              padding: 0;
              display: flex;
              justify-content: space-between;

              li {
                text-align: center;
                flex: 1 1 auto;
                flex-wrap: nowrap;

                &:not(:last-child) {
                 border-left: 1.5px solid $blue_light;
                }

                a {
                  @include x-rem('font-size', 26px); //@todo change if new font (fbspacer photoshop => 26px)
                  @include font-style('FbSpacer');
                  color: #003876;

                  &:hover {
                    color: $il_blue;
                  }
                }

                &.myeden a {
                  //color: $il_blue;
                  color: #42CBF9;
                  font-weight: 500;

                  i {
                    vertical-align: text-bottom;
                    @include x-rem('font-size', 26px);
                  }
                }
              }
            }
          }
        }
      }
      // Sticky MENU.
      &.sticky {
        position: fixed;
        z-index: 1501;
        top: 0;
        width: 100%;
        justify-content: space-between;
        flex-grow: 1;
        background: #f1f1f1;
        padding: 3px 0;
        border-bottom: 3px solid #f1f1f1;

        .wrapper {
          align-items: center;
        }

        .logo {
          flex: 0 0 auto;

          a.desktop {
            display: none;
          }
          a.mini {
            display: block;
          }
        }

        .menu {
          order: 2;
          width: auto;
          padding: 0;
          border: none;
          flex: 1;
          margin: 0 30px;

          & > ul {
            & > li {
              @include x-rem('font-size', 10px);
              font-weight: 700;

              div.submenu {
                top: 60px;
              }

              &.active {
                &:after {
                  top: 30px;
                }
              }
            }
          }
        }

        .user {
          order: 3;
          margin: 0;

          &.padding {
            padding-left: 0;
          }

          img {
            height: 32px;
          }
        }

        .country-select,
        .language-select {
          display: none;
        }
      }
    }
  }
}
