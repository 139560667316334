// MOBILE < 768
@media only screen and (max-width: $size-medium-1){
  .wng-messages {
    .messages {
      .container {
        padding-right: 0;
      }
    }
  }
}
