body:not(.page-digital-onboarding) {
  .page {
    position: relative;

    .breadcrumb {
      padding: 0 10px;
      position: absolute;
      z-index: 15;
      top: 60px;
      max-width: 1200px;
      margin: auto;
      left: 0;
      right: 0;
      width: 100%; // IE11

      .breadcrumbs {
        @include x-rem('padding', 12px 0 10px 0);
        @include x-rem('font-size', 16px);
        color: $blue;
        @include x-rem('letter-spacing', 2px);
        border-bottom: 1px solid $blue;
        display: inline-block;

        & > span {
          display: flex;
        }

        span.child {
          font-weight: 600;

          a {
            color: $blue;
            text-decoration: none;
            font-weight: 600;

            &:hover {
              text-decoration: underline;
            }
          }

          &:not(:last-child)::after {
            content: '>';
            display: inline;
            @include x-rem('margin', 0 5px);
            letter-spacing: normal;
          }
        }

        span.ya {
          margin-right: 5px;
        }
      }
    }
  }
}

body.no-main-banner {
  .page {
    .breadcrumb {
      padding-top: 10px;
      position: static;
      margin-bottom: 30px;
    }
  }
}
