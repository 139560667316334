// DESKTOP < 1200
@media only screen and (max-width: $size-large-1){
  .page-gaq {
    h1 {
      width: 60%;
    }
  }

  #block-edensprings-website-ng-commonform-website-ng-commonform {
    .webform-wrapper {
      &.default {
        width: 60%;
      }
    }
  }
}

// TABLET < 1024
@media only screen and (max-width: $size-medium-large-1){
  .page-gaq {
    h1 {
      width: 80%;
    }
  }
  #block-edensprings-website-ng-commonform-website-ng-commonform {
    .webform-wrapper {
      &.default {
        width: 80%;
      }
    }
  }
}

// MOBILE < 768
@media only screen and (max-width: $size-medium-1){

  // To be able to see the Copyright when we have the commonform bottom.
  body:not(.wng) {
    //padding-bottom: 15px; @todo voir si ça sert.
  }

  .page-gaq {
    h1 {
      width: 100%;
    }
  }

  #block-edensprings-website-ng-commonform-website-ng-commonform {
    #gaq-btn {
      top: auto;
      left: 0;
      text-align: center;
      transform: rotate(0);
      padding: 10px 15px;
      position:initial;
      border-radius:initial;
      box-shadow: 0px 8px 20px -6px rgba(0,0,0,0.56);

      span {
        max-width: initial;
        width:100%;

        i {
          display: inline-block;
        }

        br {
          display: none;
        }
      }

    }

    .webform-wrapper {
      box-shadow: none;

      &.default {
        padding: 0;
        width: 100%;
      }

      &.sticky {
        position: fixed;
        z-index: 999;
        width: 100%;
        height: 100%;
        top: -100%;
        right: auto;
        background: $blue_form;
        overflow: scroll;
      }
    }
  }
}
