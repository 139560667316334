// TABLET < 1024
@media only screen and (max-width: $size-medium-large-1){
  .login-container {
    box-shadow: none;

    #user-login {
      margin: 0 auto;
      width: 80%;
    }
  }
}

// MOBILE < 768
@media only screen and (max-width: $size-medium-1){
  // User login for no MyEden pages
  .login-container {
    box-shadow: none;

    #user-login {
      width: 100%;
      margin: 0;
    }
  }

  .page-user {

    .ess-user-login {
      margin-top: 30px;
      display: flex;
      flex-direction: column-reverse;

      & > div {
        &#user-login-text-block {
          margin-left: 0;
          margin-top: 20px;
        }

        &#login-form {
          margin-bottom: 15px;

          form {
            width: 100%;
          }
        }
      }
      &.multi-login {
        & > .form-container {

          ul.tab {
            width: 100%;
          }
          .tab-phone {
            margin-bottom: 20px;
          }

          #edensprings-self-service-user-login-otp {
            .phone-container {
              display: flex;
              flex-direction: row-reverse;
              .form-item-phone-prefix {
                flex: 1 1 60%;
                margin-right: 15px;
              }
            }
          }
        }
      }
    }

    .ss-user-register {
      margin-top: 20px;

      form {
        width: 80%;
      }
    }
  }
}

// MOBILE < 490
@media only screen and (max-width: $size-small-1){
  .page-user {

    .ess-user-login {

      #edensprings-self-service-user-login-otp {
        .phone-container {
          .form-item-phone-prefix {
            flex: 1 1 100%;
          }
        }
      }

      &.multi-login {
        & > .form-container {
          #edensprings-self-service-user-login-otp {
            .phone-container {
              .form-item-phone-prefix {
                flex: 1 1 100%;
              }
            }
          }
        }
      }
    }

    .ss-user-register {
      form {
        width: 100%;
      }
      &.multi-registration {
        ul.tab {
          width: 100%;
        }
      }
    }

  }
}