@keyframes fadeIn {
  0% {
    left: -100%;
  }
  100% {
    left: 0;
  }
}

#block-edensprings-website-ng-blocks-website-ng-v3-cta {
  position: fixed;
  left: 0;
  z-index: 10;
  top: 30%;
  animation: 1.5s fadeIn;
  animation-fill-mode: forwards;

  .floating-buttons {
    text-align: center;
    display: flex;
    flex-direction: column;

    & > div {
      clear: both;
      
      a {
        @include font-style('FbSpacer', 800);
        width: 100%;
        display: block;
        padding: 18px 15px;
        color: #fff;
        @include x-rem('font-size', 22px);
        font-weight: 500;
        line-height: 22px;
        text-align: center;
        text-transform: uppercase;
        border-radius: 0 10px 10px 0;
        transition: all 0.5s ease;
        float: left;

        &.get-an-offer {
          background: $orange_cta;
        }


        &.subscribe-online {
          background: $orange_cta;
        }

        &:hover {
          width: calc(100% + 20px);
        }
      }

      &:first-child {
        a {
          margin-bottom: 10px;
        }
      }
    }
  }
}

