body {
  @include x-rem('font-size', 20px);
  line-height: 1.2;
}

p {
  line-height: 1.2;
  @include x-rem('font-size', 20px);
  color: $grey;
}

h1,
.h1,
.extrabold {
  @include x-rem('font-size', 40px);
  @include font-style('FbSpacer', 800);
}



h2,
.h2,
.bold {
  font-weight: 700;
  @include x-rem('font-size', 34px);
  @include x-rem('letter-spacing', 1.5px);
  @include x-rem('line-height', 32px);
  color: $blue;
}

h3,
.h3,
.semi-bold {
  font-weight: 600;
  @include x-rem('font-size', 30px);
  @include x-rem('letter-spacing', 1.5px);
  @include x-rem('line-height', 30px);
}

input[type="text"] {
  font-weight: 400;
  @include x-rem('font-size', 20px);
  @include x-rem('letter-spacing', 1px);
  @include x-rem('line-height', 22px);
}

.material-icons {
  vertical-align: middle;
}

// Material Icons
.material-icons.md-12 {
  @include x-rem('font-size', 12px);
}

.material-icons.md-16 {
  @include x-rem('font-size', 16px);
}

.material-icons.md-18 {
  @include x-rem('font-size', 18px);
}

.material-icons.md-24 {
  @include x-rem('font-size', 24px);
}

.material-icons.md-28 {
  @include x-rem('font-size', 28px);
}

.material-icons.md-34 {
  @include x-rem('font-size', 34px);
}

.material-icons.md-36 {
  @include x-rem('font-size', 36px);
}

.material-icons.md-48 {
  @include x-rem('font-size', 48px);
}

.material-icons.md-72 {
  @include x-rem('font-size', 72px);
}

.material-icons.md-inactive {
  opacity: 0.3;
}

.material-icons {
  &.green {
    color: $green;
  }
  &.orange {
    color: $orange;
  }
  &.red {
    color: $red;
  }
  &.blue_light {
    color: $blue_light;
  }
}

pre {
  padding: 30px 15px;
  background: #f7f7f7;
  word-break: break-all;
  word-wrap: break-word;
  white-space: pre;
  border-left: 3px solid #7b7b7b;
  overflow-x: auto;
}
