.main-banner {
  position: relative;
  margin-bottom: 30px;

  .slide-item {
    overflow: hidden;

    img {
      display: block;
      position: relative;
      border-bottom-left-radius: 65% 20%;
      border-bottom-right-radius: 65% 20%;
      max-width: none;
      height: 500px;
      left: auto;
      margin-left: 0;
      margin-right: -960px;
      right: 50%;
    }

    .content {
      position: absolute;
      width: 100%;
      top: 120px;
      z-index: 3;
      left: 0;
      right: 0;
      margin: auto;
      max-height: 75%;
      overflow: hidden;
      .container {
        position: relative;
        height: 360px;
        .banner-texts-center,
        .banner-texts-left {
          width: 50%;
          //background: rgba(255, 255, 255, 0.5);
          padding: 20px;
          border-radius: 15px;
          .title {
            display: inline-block;
            width: 80%;
            font-weight: 800;
            color: $il_blue;
            @include x-rem('font-size', 41px);
            @include x-rem('letter-spacing', 2px);
            @include x-rem('line-height', 40px);
            padding-bottom: 15px;
          }

          .field-name-field-wngh-banner-description {
            width: 100%;
            color: $blue;
            @include x-rem('font-size', 22px);
            @include x-rem('line-height', 22px);
            font-weight: 400;
            margin: 0;
          }

          .cta-links {
            display: flex;
            justify-content: right;
            position: absolute;
            right: 15px;
            bottom: 58px;
            a:first-child {
              .cta-link {
                margin: 30px 0px 10px 0;
              }
            }
            .cta-link {
              margin: 30px 10px 10px 0;
              text-decoration: none;
              padding: 16px 42px;
              background: #ffa401;
              //max-width: 300px;
              border-radius: 30px;
              text-align: center;
              color: $white;
              font-weight: bold;
              @include x-rem('font-size', 22px);
            }
          }
        }

        .banner-texts-center {
          margin: 0 auto;
          text-align: center;

          .cta-links {
            justify-content: center;
            .cta-link {
              margin: 30px 10px 10px 10px;
            }
          }
        }
      }

    }
  }

  .icons-container {
    height: 120px;
  }

  .icons {
    position: absolute;
    top: 435px;
    left: 0;
    right: 0;
    margin: auto;

    .field-items {
      display: flex;
      justify-content: space-around;

      .field-item {
        margin: 0;
        flex: 0 0 20%;

        .fc-feature {
          text-align: center;
          max-width: 150px;
          margin: auto;

          .icon {
            margin: auto;
            background: $white;
            @include box-shadow-content();
            border-radius: 50%;
            width: 120px;
            height: 120px;
            padding: 15px;

            svg {
              //background: $blue_light;
              width: 60px;
              height: 60px;
              margin: 15px 0;
              fill: url(#icon-gradient) $blue_light;
            }
          }

          .icon-label {
            text-align: center;
            width: 100%;
            @include x-rem('font-size', 16px);
            line-height: 20px;
            color: $blue;
            margin: 15px 0 0;
            font-weight: 500;
            //white-space: nowrap;
          }
        }

        &:first-child,
        &:last-child {
          margin-top: -20px;
        }
      }
    }
  }

  .slick-dots {
    position: absolute;
    top: 450px;
    left: 0;
    right: 0;
    margin: auto;
    text-align: center;
    padding: 0;

    li {
      cursor: pointer;
      display: inline-block;
      text-indent: -9999px;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      //background: linear-gradient(90deg, #0d4889, #0dbcf2, #ffde0d, #f5973d, #f0634a);
      background: white;
      margin: 0 5px;
      vertical-align: middle;
      padding: 0;

      &.slick-active {
        width: 15px;
        height: 15px;
      }
    }
    & li:nth-child(1) {
      background: #f0634a;
    }
    & li:nth-child(2) {
      background: #f5973d;
    }
    & li:nth-child(3) {
      background: #ffde0d;
    }
    & li:nth-child(4) {
      background: #0dbcf2;
    }
    & li:nth-child(5) {
      background: #0d4889;
    }
  }
}
