body.page-residential-onboarding,
body.page-business-onboarding {
  &.disable-click {
    pointer-events: none;

    &:after {
      content: "";
      width: 100%;
      height: 100%;
      position: fixed;
      top: 0;
      background: rgba(0, 0, 0, 0.2);
      display: block;
      z-index: 999;
    }
  }
  .page {
    background: none;
  }

  .old-price {
    margin: 0 0 0 10px;
  }

  .ss-messages {
    margin-top: 20px;
  }

  .page-step {
    padding: 30px 0;
  }

  .top-btn {
    text-align: right;
    margin-bottom: 10px;

    a.btn-back {
      color: $grey_value;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  // Step 1.
  #edensprings-rob-customer-form-step1 {
    width: 75%;
    margin: 80px auto;
    text-align: center;

    .zip-intro {
      margin-bottom: 30px;
      display: block;
      font-weight: bold;
    }

    label {
      font-weight: normal;
    }

    input#edit-zip-code {
      width: auto;
    }

    input#edit-zip-code:not(:placeholder-shown) {
      text-transform: uppercase;
    }

    input#edit-city {
      width: 50%;
    }
  }

  // Step 2.
  #edit-main-container {
    display: flex;

    input#edit-zip-code:not(:placeholder-shown) {
      text-transform: uppercase;
    }

    #edit-left {
      flex: 1;

      #edit-section2, #edit-section2--2 {
        justify-content: flex-start;
      }

      .form-item-prefix-phone, .form-item-billing-prefix-phone {
        flex: 0 0 calc(10% - 10px);
      }

      .form-item-city, .form-item-billing-city {
        flex: 0 0 calc(33% - 10px);
      }

      .form-item-address, .form-item-billing-address {
        flex: 0 0 calc(38%);
      }

      .form-item-house-number, .form-item-billing-house-number {
        flex: 0 0 calc(26% - 10px);
      }

      h1 {
        margin-bottom: 60px;
      }
      form.item {
        input {
          box-shadow: none;
          border: 2px solid #B2B2B2;
          color: $blue_light;
          &.error {
            border: 2px solid $red;
          }
        }
      }


      select {
        &:not(:placeholder-shown) {
          border: 2px solid #B2B2B2;
          box-shadow: none;
          color: $blue_light;
        }
      }

      textarea {
        box-shadow: none;
        border: 2px solid #B2B2B2;
        border-radius: 10px;
        color: $blue_light;
        padding: 12px 20px;

        &:not(:placeholder-shown) {
          border: 2px solid $blue_light;
        }
      }

      .grippie {
        display: none;
      }

      // Select 2
      .select2-results .select2-result-label {
        color: $blue_light !important;
      }

      .select2-container {
        height: 100%;
        width: 100% !important;

        &.error {
          border: none;

          & > .select2-choice {
            border-color: $red;
          }
        }

        &.select2-dropdown-open {
          height: 100% !important;
        }

        .select2-choice {
          height: 100%;
          border-radius: 10px;
          border: 2px solid #B2B2B2;
          box-shadow: none;
          background-image: none;
          padding: 10px;
          color: $blue;

          .select2-arrow {
            background-image: none;
            background: $white;
            border-right: none;

            b {
              background-position: 2px 10px;
              background: url(/profiles/edensprings/themes/eden_ng/images/arrow.svg) no-repeat 5px;
            }
          }
        }
      }

      .select2-dropdown-open {
        .select2-choice {
          background-color: $white;
          max-height: 100% !important;
        }

        .select2-arrow,
        .select2-chosen {
          //padding-top: 8px;
        }
      }

      .form-disabled {
        .select2-container {
          .select2-choice {
            .select2-arrow {
              background: none;
            }
          }
        }
      }

    }

    #edit-right {
      flex: 0 0 40%;
      margin: 0px 15px 0 0;

      span.form-required {
        display: none;
      }

      #edit-bottles {
        color: $blue_light;
        border: 1px solid #d8d8d8;
        box-shadow: none;
      }

      .discount-container {
        display: flex;
        align-items: center;

        .form-item-discount-input {
          flex: 0 0 200px;
          padding: 0;
          margin-left: 10px;

          input {
            padding: 12px 15px;
            box-shadow: none;
            border: 1px solid #d8d8d8;
            color: $blue_light;
          }
        }

        #edit-discount-btn {
          border-radius: 25px;
          padding: 10px 35px;
          @include x-rem("font-size", 16px);
          line-height: 28px;
          box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);

          &:not(.form-disabled) {
            background-color: $blue_light;

            &:hover {
              background-color: $blue;
            }
          }
        }

        &.active {
          #edit-discount-btn {
            &:not(.form-disabled) {
              background-color: $grey_disabled;
              color: $grey;

              &:hover {
                background-color: $grey_disabled;
                color: $grey;
              }
            }
          }
        }
      }

      & > .form-item-delivery-flag {
        border-bottom: 1px solid $grey_disabled;
        margin-bottom: 10px;
        padding-bottom: 20px;

        #edit-delivery-flag {
          .form-item {
            margin: 0;
            padding: 0 0 5px 0;

            label {
              @include x-rem("font-size", 15px);
            }
          }
        }

        .description {
          color: $grey_transparent;
        }
      }
    }
  }

  .package-price {
    margin-top: 10px;

    .discount-header {
      text-align: right;
      margin-bottom: 2px;
      @include x-rem('font-size', 16px);
      font-weight: bold;
    }

    .discount-message {
      text-align: right;
      margin-bottom: 10px;
      color: $red;
    }

    .rental-price,
    .price-without-vat,
    .vat,
    .total-price {
      display: flex;
      padding-bottom: 10px;
      margin: 0 0 10px;
      justify-content: flex-start;
      border-bottom: 1px solid $grey_disabled;
      align-items: center;

      .label {
        font-weight: normal;
        color: $grey_label;
        margin-left: 10px;
        width: 200px;
      }

      .old-price {
        @include x-rem('font-size', 18px);
        line-height: 20px;
        text-decoration: line-through;
        width: 85px;
        text-align: center;
      }

      .price {
        @include x-rem('font-size', 22px);
        color: $blue;
        font-weight: bold;
        line-height: 22px;
        text-align: right;

        span {
          display: block;
        }
      }

      .price-suffix {
        font-weight: normal;
        @include x-rem('font-size', 16px);
      }

      .price-discount {
        margin-right: 10px;
        span {
          color: $red;
        }
      }
    }

    //.rental-price,
    .price-without-vat,
    .vat {
      border: 0;
      @include x-rem('font-size', 16px);
      padding: 0;
      margin-bottom: 10px;
      line-height: 16px;
    }

    .rental-price {
      .label {
        display: flex;
      }
    }


    .price-without-vat,
    .vat {
      margin: 0 0 5px;

      .old-price {
        @include x-rem('font-size', 16px);
        color: $grey_label;
      }

      .price {
        @include x-rem('font-size', 16px);
        color: $grey_label;
      }

    }
  }

  .select2-search .select2-input {
    line-height: 16px;
    @include x-rem('font-size', 16px);
  }

  #edit-sub-container {
    width: 40%;
    margin-right: auto;
    padding-top: 10px;
  }

  .secure-img {
    text-align: left;
  }

  .payment-methods {
    display: flex;
    margin-top: 15px;
    flex-wrap: wrap;
    justify-content: flex-end;

    img {
      margin: 0 0 15px 10px;
      height: 40px;
    }
  }

  // Step 3
  #edensprings-rob-customer-form-step3 {
    #edit-payment-methods {
      margin-bottom: 15px;
      padding-bottom: 15px;

      .form-item-payment-methods {
        margin: 10px 0;
        padding: 10px 0;
        border-bottom: 1px solid $grey_light;
        align-items: center;
        display: none;

        &.validated {
          display: flex;
        }

        label {
          display: flex;
          border-bottom: 3px solid $white;
          cursor: pointer;
          border-radius: 3px;
          width: 100%;
          color: $blue;
          font-weight: 800;
          flex-wrap: wrap;
          padding-right: 28px;

          .img-container {
            flex: 1;
            text-align: left;

            img {
              display: inline-block;
            }
          }

          &[for="edit-payment-methods-cc-card"] {
            .img-container {
              display: flex;
              justify-content: flex-end;

              img {
                margin: 0 5px;
              }
            }
          }

          .payment-disclaimer {
            margin-top: 10px;
            display: none;
            @include x-rem("font-size", 14px);
            line-height: 18px;
            color: $grey_transparent;
            font-weight: normal;
            flex-basis: 100%;
          }
        }

        &:last-child {
          border: none;
        }
      }
    }

    .rent-payment-methods {
      #edit-payment-methods {
        .form-item-payment-methods {
          display: flex;
        }
      }
    }
  }

  // Step 4
  #edensprings-rob-customer-form-step4 {
    input[type="text"] {
      font-weight: normal;

      &:not(:placeholder-shown) {
        border-color: $grey_value;
      }
    }

    #edit-left {
      width: 75%;

      input {
        &:not(:placeholder-shown) {
          border-color: #d8d8d8;
        }
      }
    }

    .row {
      display: flex;
      align-items: center;
      margin: 15px 0 30px;
      justify-content: space-between;
    }

    .SfcField {
      border-radius: unset;
      @include x-rem("padding", 12px 20px);
      border: 1px solid #d8d8d8;
      width: 100%;
      position: relative;
      margin-left: 15px;
      text-align: center;
      border-radius: 10px;
    }

    #card-number {
      text-align: left;
    }

    #card-expiry {
      flex: 0 0 130px;
    }

    #card-cvc {
      flex: 0 0 80px;
      margin-left: 0;
    }

    .form-item {
      padding: 0;
      &.form-item-keep-cc {
        padding: 10px 0;
      }
    }

    .inputField {
      color: $blue;
    }
  }

  // Step 5
  #edensprings-rob-customer-form-step5 {
    .payment-disclaimer {
      margin-top: 10px;
      @include x-rem("font-size", 14px);
      line-height: 18px;
      color: $grey_transparent;
      font-weight: normal;
      flex-basis: 100%;
    }
    label {
      display: flex;
    }
    .description {
      text-align: left;
      background: url(../images/help.png) no-repeat left center;
      padding-left: 20px;
      margin-left: 3px;
      color: #06a7e0;
    }

    /* Tooltip container */
    .tooltip {
      position: relative;
      display: inline-block;
      border-bottom: 1px dotted black; /* If you want dots under the hoverable text */

      &:hover {
        .tooltiptext {
          visibility: visible;
          opacity: 1;
        }
      }

      /* Tooltip text */
      .tooltiptext {
        visibility: hidden;
        min-width: 120px;
        max-width: 330px;
        background-color: #555;
        color: #fff;
        text-align: center;
        padding: 10px;
        border-radius: 6px;

        /* Position the tooltip text */
        position: absolute;
        z-index: 1;

        /* Fade in tooltip */
        opacity: 0;
        transition: opacity 0.3s;
      }

      .tooltip-right {
        top: -5px;
        left: 125%;
      }
    }
  }

  // Step 6
  #edensprings-rob-customer-form-step7 {
    iframe {
      margin: auto;
      width: 100%;
      min-height: 600px;
      border: 0;
    }
  }

  //.package-price {
  //  margin-top: 10px;
  //
  //  .discount-message {
  //    text-align: right;
  //    margin-bottom: 10px;
  //    color: $red;
  //  }
  //
  //  .price-discount {
  //    text-align: right;
  //    margin-bottom: 10px;
  //
  //    span {
  //      margin-right: 10px;
  //      display: inline-block;
  //      color: $red;
  //    }
  //  }
  //
  //  .price-without-vat,
  //  .vat,
  //  .total-price {
  //    display: flex;
  //    padding-bottom: 10px;
  //    margin: 0 0 10px;
  //    border-bottom: 1px solid $grey_disabled;
  //    align-items: center;
  //
  //    .label {
  //      font-weight: normal;
  //      color: $grey_label;
  //      margin-left: 10px;
  //    }
  //
  //    .old-price {
  //      @include x-rem("font-size", 20px);
  //      line-height: 18px;
  //      margin-right: 10px;
  //      text-decoration: line-through;
  //    }
  //
  //    .price {
  //      @include x-rem("font-size", 22px);
  //      color: $blue;
  //      font-weight: bold;
  //      line-height: 20px;
  //    }
  //
  //    .price-suffix {
  //      font-weight: normal;
  //      @include x-rem("font-size", 16px);
  //    }
  //  }
  //  .total-price {
  //    border-bottom: 0;
  //  }
  //
  //  .price-without-vat,
  //  .vat {
  //    border: 0;
  //    @include x-rem("font-size", 16px);
  //    padding: 0;
  //    margin-bottom: 10px;
  //    line-height: 16px;
  //  }
  //}

  .package-option {
    text-align: right;
    line-height: 18px;
    margin-bottom: 10px;

    .label {
      font-weight: bold;
      color: $blue;
    }
  }

  .form-line {
    display: flex;
    margin-bottom: 15px;
    justify-content: space-between;

    .form-item {
      flex: 0 0 calc(50% - 10px);
      padding: 0;
      margin-left: 20px;

      &:last-child {
        margin-right: 0;
      }
    }

    &.three-item {
      .form-item {
        margin-right: 15px;
        flex: 0 0 calc(100% / 3 - 10px);

        &:last-child {
          margin-right: 0;
        }
      }
    }

    &.full-width {
      .form-item {
        flex: 0 0 100%;

        &:first-child {
          margin-right: 0;
        }
      }
    }
  }

  .disclaimer-field {
    color: $blue_light;
    //@include x-rem('font-size', 14px);
    line-height: 16px;
  }

  .disclaimer-deposit {
    font-weight: bold;
    @include x-rem("font-size", 14px);
    line-height: 16px;
  }

  .disclaimer-charges {
  }

  .disclaimer-payment {
    margin: 20px 0 10px;
    @include x-rem("font-size", 18px);
    line-height: 20px;
  }

  // Will be used step 2 and thank you page.
  .rob-package {
    //padding: 10px 15px;
    @include box-shadow-content();
    background: $white;
    margin-bottom: 10px;
    border: 2px solid $blue;
    border-radius: 10px;
    box-shadow: none;

    h2 {
      @include x-rem("font-size", 24px);
      font-weight: bold;
      margin: 0 0 15px;
      background: #252d5c;
      color: white;
      padding: 5px 15px;
      border-radius: 6px 6px 0 0;
    }

    .package-name {
      display: block;
      color: $blue;
      font-weight: bold;
      margin: 0 10px 25px;
      @include x-rem("font-size", 18px);
    }

    .package-info {
      display: flex;
      align-items: center;
      margin: 0 10px 10px 10px;

      img {
        flex: 0 0 200px;
      }

      .package-description {
        flex: 0 0 calc(100% - 200px);
        margin-right: 10px;

        .desc {
          font-weight: 600;
          margin-bottom: 15px;
          line-height: 18px;
        }

        .label {
          font-weight: 600;
          color: $grey_label;
        }

        .value {
          color: $blue_light;
          font-weight: bold;
          @include x-rem("font-size", 18px);
        }

        .total {
          margin-top: 10px;
        }
      }
    }

    .package-disclaimer {
      @include x-rem("font-size", 14px);
      line-height: 14px;
      font-weight: 500;
      margin: 20px 10px 20px 0;
    }
  }

  // CONFIRMATION PAGE.
  .confirmation-page {
    padding: 30px 0 0;

    h1 {
      text-align: center;
    }

    .text {
      margin-bottom: 30px;
    }

    .information {
      display: flex;

      .info-summary {
        flex: 0 0 60%;
        max-width: 60%;
        display: flex;

        h2 {
          margin-top: 0;
        }

        & > div {
          margin-left: 30px;
          flex: 0 0 calc(50% - 30px);
          max-width: calc(50% - 30px);
        }

        .item {
          margin-bottom: 5px;
        }

        .label {
          color: $blue_light;
          font-weight: 600;
          display: block;
          line-height: 18px;
        }
      }

      .package-container {
        flex: 1;

        .rob-package {
          width: 100%;
        }
      }
    }

    .total-price {
      border: none;
      padding: 0;
      margin: 0;
    }
  }

  // GENERAL ELEMENTS.
  textarea {
    width: 100%;
  }

  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: $grey_value;
    opacity: 1; /* Firefox */
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: $grey_value;
  }

  ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: $grey_value;
  }

  .submit-btn {
    display: flex;
    justify-content: flex-end;

    img {
      display: none;
      width: 45px;
      height: 45px;
      margin: 5px 15px 5px 0;
    }
  }

  input[type="submit"] {
    margin: 5px 0;
    @include x-rem("font-size", 18px);
    font-weight: 500;
    background: $blue_light;
    border-radius: 25px;
    padding: 10px 35px;
    line-height: 28px;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);

    &:hover {
      background: $blue_light;
    }
  }

  input[type="submit"]:disabled {
    background: #b2b2b2;
    cursor: default;
    color: $white;
    opacity: 0.5;
    border-radius: 25px;
    padding: 10px 35px;
  }

  input[type="text"]:disabled {
    background: #e6f6fc;
    color: $blue_light;
  }

  [type="radio"]:checked,
  [type="radio"]:not(:checked) {
    position: absolute;
    right: -9999px;
  }
  [type="radio"]:checked + label,
  [type="radio"]:not(:checked) + label {
    position: relative;
    //padding-left: 28px;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
    color: #666;
  }
  [type="radio"]:checked + label:before,
  [type="radio"]:not(:checked) + label:before {
    content: "";
    position: absolute;
    right: 0;
    width: 20px;
    height: 20px;
    border: 1px solid #ddd;
    border-radius: 100%;
    background: #fff;
  }
  [type="radio"]:checked + label:after,
  [type="radio"]:not(:checked) + label:after {
    content: "";
    width: 12px;
    height: 12px;
    background: $blue_light;
    position: absolute;
    right: 4px;
    top: 4px;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
  }
  [type="radio"]:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  [type="radio"]:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  .form-item {
    label {
      margin-bottom: 5px;

      p {
        margin: 0;
        line-height: normal;
      }
    }

    &.form-type-checkbox {
      display: flex;
      align-items: center;

      input {
        margin-left: 5px;
        flex: 0 0 14px;
      }

      label {
        margin-bottom: 0;
        @include x-rem("font-size", 18px);
      }
    }

    &.form-item-different-billing {
      margin-bottom: 20px;
    }

    &.form-item-comment {
      padding: 0;
      margin-bottom: 20px;
    }
  }

  .disclaimer-field {
    margin-bottom: 10px;
  }

  &.sticky {
    .page {
      padding-top: 0;
    }
  }

  .block-edensprings-rob {
    margin-bottom: 60px;
  }

  .rob-breadcrumb {
    display: flex;
    justify-content: space-around;
    margin: 15px auto 30px;
    width: 100%;

    .rob-step {
      text-align: center;
      position: relative;
      flex: 0 25%;

      a.step-circle,
      span.step-circle {
        height: 40px;
        width: 40px;
        background: #fff;
        border: 2px solid $blue;
        border-radius: 50%;
        text-align: center;
        line-height: 36px;
        display: inline-block;
        color: $blue;
        margin-bottom: 10px;
        font-weight: bold;
        @include x-rem("font-size", 18px);
      }

      span {
        display: block;
        color: $blue;
        font-weight: bold;
        @include x-rem("font-size", 16px);
        letter-spacing: 1px;
        line-height: 18px;
        padding: 0 5px;
      }

      &:first-child:before {
        display: none;
      }

      &:before {
        content: "";
        position: absolute;
        top: 20px;
        right: -50%;
        height: 2px;
        background: $blue;
        width: 100%;
        z-index: -1;
      }

      &.completed {
        a.step-circle,
        span.step-circle {
          background: $blue;
          color: #fff;
        }
      }

      &.pending {
        a.step-circle,
        span.step-circle {
          border-color: $blue_light;
          color: $blue_light;
        }
        span {
          color: $blue_light;
        }
        &:before {
          background: $blue_light;
        }
      }

      &.current {
        span.step-circle {
          background: #fff;
          color: $blue;
        }
      }
    }

    &.small {
      .rob-step {
        flex: 0 33.33%;
      }
    }
  }
  &.i18n-ru {
    // Step 3
    #edensprings-rob-customer-form-step3 {
      #edit-payment-methods {
        .form-item-payment-methods {
          display: flex;
        }
      }
    }
  }
}
