@media only screen and (max-width: $size-medium-1){
  #block-edensprings-website-ng-blocks-website-ng-v3-cta {
    display: none;
  }

  #header-content .top-header {
    position: static;
    animation: none;

    &.sticky {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      z-index: 100;
    }
  }
}
