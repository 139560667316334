@include font-face("FbSpacer", "../font/FbSpacer-Regular", 400, normal);
@include font-face("FbSpacerBold", "../font/FbSpacer-Bold", 800, normal);

body.page-gaq {

  .page {
    background: url('../images/israel/background-gaq.jpg') no-repeat center 0;
    background-size: cover;
    padding-bottom: 150px;
  }

  ul {
    padding: 0;
  }

  .webform-wrapper {
    width: 45%;
    @include box-shadow-content();
    padding: 20px;
  }
}

.page {
  @include x-rem('padding-bottom', 15px);
}

body.no-main-banner .page{
  //margin-top: 60px;
}

h1 {
  color: $il_blue;
}

.hide-element {
  display: none;
}


/**
 * Sticky header
 */
body.sticky {
  header {
    #block-edensprings-israel-blocks-israel-header {
      #header-content {
        .wrapper {
          .right {
            display: flex;
            justify-content: space-evenly;
            .logo-sticky {
              display: block;
            }
          }
          .left {
            .top-header {
              display: none;
            }
            .menu {
              margin: 10px 0;
            }
          }
        }
      }
    }
  }
}

//PAUZA Override
body.i18n-pauza {
  header {
    .region {
      #block-edensprings-israel-blocks-israel-header {
        .content {
          .right {
            a.home-link {
                margin: 15px 0;
              }
          }
        }
      }
    }
  }

  .page {
    background: none;

    div#user-login-text-block {
      ul {
        li {
          span {
            &:before {
              border-top-left-radius: 50%;
            }          
          }
        }
      }
    }    
  }  
 
} 
