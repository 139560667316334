// < 1024
@media only screen and (max-width: $size-medium-large-1) {
  body.page-residential-onboarding,
  body.page-business-onboarding {
    // Step 2.
    #edit-main-container {
      display: block;

      #edit-right {
        margin: 30px 0 0;
      }

      &.reverse-order {
        #edit-right {
          margin: 0 0 0;
        }

        #edit-left {
          margin-top: 30px;
          border-bottom: 1px solid $grey_disabled;
        }
      }
    }
  }
}

// < 768
@media only screen and (max-width: $size-medium-1) {
  body.page-residential-onboarding,
  body.page-business-onboarding {

    h1 {
      @include x-rem('font-size', 32px);
    }

    // Step 1.
    #edensprings-rob-customer-form-step1 {
      width: 100%;

      input#edit-city {
        width: 100%;
      }
    }

    // Step 2
    form#edensprings-rob-customer-form-step2 {
      #edit-top-container {
        margin-bottom: 30px;

        h1 {
          padding-bottom: 0;
        }
      }
      #edit-main-container {
        display: flex !important;
        flex-direction: column;

        &.reverse-order {
          flex-direction: column-reverse;
        }

        #edit-left {
          //order: 2;

          .form-item-prefix-phone, .form-item-billing-prefix-phone {
            flex: 0 0 calc(20% - 10px);
            margin-right: 10px;
          }
        }

        #edit-right {
          //order: 1;
          display: flex;
          flex-direction: column;
          .rob-package {
            //order: 4;
            margin-bottom: 20px;
          }
          .form-type-select {
            //order: 1;
          }
          .discount-container {
            //order: 2;
          }
          .package-price {
            //order: 3;
            .total-price {
              border-bottom: 0;
              padding-bottom: 0;
              margin-bottom: 20px;
            }
          }
        }
      }
      #edit-sub-container {
        width: 100%;
        .secure-img {
          text-align: right;
        }
      }
    }

    #edensprings-rob-customer-form-step2 {
      .form-item {
        margin-left: 0;
      }
      
      .form-item-address, .form-item-billing-address {
        margin-bottom: 15px;
      }

      .select2-container {
        .select2-choice {
          .select2-arrow {
            b {
              background-image: none !important;
              background: url(/profiles/edensprings/themes/eden_ng/images/arrow.svg) no-repeat 5px !important;
            }
          }
        }
      }
    }

    #block-edensprings-rob-rob-logo {
      max-width: 100%;
      padding: 13px 10px;
    }

    .rob-package {
      text-align: center;

      .package-info {
        display: block;

        .package-description {
          margin-left: 0;
        }
      }
    }

    #edensprings-rob-customer-form-step3 {
      #edit-payment-methods {
        .form-item-payment-methods {
          label {
            display: block;

            .img-container {
              margin-top: 10px;
            }
          }
        }
      }
    }

    // Step 4
    #edensprings-rob-customer-form-step4 {
      #edit-left {
        width: 100%;
      }

      .row {
        display: block;
        text-align: center;
      }

      .SfcField {
        margin-bottom: 15px;
      }
    }

    // Step 5
    #edensprings-rob-customer-form-step5 {
      #iframe-signaturit {
        height: 400px;
      }
    }

    // CONFIRMATION PAGE.
    .confirmation-page {
      .information {
        display: block;

        .info-summary {
          display: block;
          max-width: 100%;

          & > div {
            flex: 0 0 calc(100% - 30px);
            max-width: calc(100% - 30px);
          }

          h2 {
            margin-bottom: 10px;
          }

          & > div {
            margin-bottom: 30px;
          }
        }
      }
    }

    .form-line {
      display: block;

      &.three-item {
        .form-item {
          margin: 0 0 15px;
        }
      }

      &#edit-section2, &#edit-section2--2 {
        display: flex;

        .form-item:first-child {
          margin: 0;
        }

        .form-item-phone {
          flex: 0 0 80%;
        }
      }

      .form-item:first-child {
        margin: 0 0 15px;
      }
    }

    .submit-btn {
      text-align: left;
    }

    .secure-img {
      text-align: left;

      img {
        width: 250px;
      }
    }

    .cards-img {
      justify-content: flex-start;

      img {
        width: 75px;
      }
    }
  }
}
