footer {
  @include x-rem('padding', 0 0 10px);
  background: $il_blue;
  color: $white;
  overflow: hidden;

  .curved {
    height: 85px;
    background-color:#fff;
    border-bottom-left-radius: 190% 300%;
    border-bottom-right-radius: 190% 300%;
  }

  .region-footer {
    @include x-rem('padding-top', 20px);
  }

  #block-edensprings-website-ng-blocks-website-ng-v3-footer {
    .footer-top {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 10px;

      ul.footer-certifications {
        margin: 0;
        padding: 0;

        li {
          margin: 0 10px;
          padding: 0;
          display: inline-block;
        }
      }

      .separator {
        height: 20px;
        width: 1px;
        background: $blue;
        margin: 0 30px;
      }

      ul.footer-social {
        margin: 0;
        padding: 0;
        position: relative;

        li {
          margin: 0 3px;
          padding: 0;
          display: inline-block;

          a, span.js-link {
            text-indent: -9999px;
            width: 24px;
            height: 24px;
            display: inline-block;            
            background: url('../images/israel/social-sprite-v3.png') no-repeat transparent;
            opacity: 0.8;
            transition: opacity 0.5s ease;

            &:hover {
              opacity: 1;              
            }
          }

          &.tiktok {
            a, span.js-link {              
              background-position: 0 -48px;              
            }
          }          

          &.youtube {
            a, span.js-link {
              background-position: 0 -115px;
            }
          }

          &.instagram {
            a, span.js-link {
              background-position: 0 -24px;
            }
          }

          &.vk {
            a, span.js-link {
              background-position: 0 -92px;
            }
          }
        }
      }
    }

    .footer-bottom {
      display: flex;
      align-items: center;
      justify-content: center;
      @include x-rem('font-size', 18px);
      flex-direction: column;
      text-align: center;

      // Footer menu (One level only)
      ul {
        margin: 0;
        padding: 0;

        li {
          display: inline-block;
          line-height: 14px;

          a,
          span.js-link {
            letter-spacing: 1.3px;

            &.active {
              color: inherit;
            }

            &:hover {
              color: $blue_light;
            }
          }
        }
      }
      // Menu footer (multi level)
      .menu-container {
        flex-wrap: wrap;
        justify-content: center;
        display: flex;

        .group-links {
          margin: 10px 20px;
          max-width: 25%;

          .group-title {
            color: #42CBF9;
            font-weight: bold;
            text-align: right;
            display: block;
            @include x-rem('font-size', 20px);
            padding-bottom: 5px;
            text-decoration: underline;
          }
          ul {
            li {
              display: block;
              text-align: right;
              padding: 5px 0;
              a {
                color: $white;
              }
              &:hover {
                text-decoration: underline;
              }
            }

          }

        }
      }
    }
  }
}
.page-gaq {
  footer {
    .curved {
      display: none;
    }
  }
}
