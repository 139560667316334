// TABLET < 1024
@media only screen and (max-width: $size-large) {
  .node-type-ingv-homepage {
    .square-blocks {
      .field-name-field-wngv-home-square-blocks {
        & > .field-items {
          & > .field-item {
            .square-block {
              margin: 0 5px;
              .inner {
                h2,
                h2 a {
                  line-height: 26px;
                  @include x-rem("font-size", 24px);
                }
              }
            }
          }
        }
      }
    }

    .drops-list {
      .titles {
        .slick-slide {
          .item {
            svg {
              width: 182px;
              height: 219px;
            }
            span {
              top: 105px;
              @include x-rem("font-size", 22px);
            }
          }
        }
      }
    }

    .blog {
      width: 100%;

      .blog-image {
        padding: 0;
        img {
          border-radius: 0 18px 18px 0;
          padding: 0;
          margin-bottom: 10px;
        }
      }
      .blog-infos {
        padding: 0 10px;
        text-align: center;
        h2 {
          margin-top: 0;
        }
        .more-details {
          a {
            border-radius: 15px 15px 0 0;
          }
        }
      }
    }
  }
}

// MOBILE < 768
@media only screen and (max-width: $size-medium-1) {
  .node-type-ingv-homepage {
    #block-edensprings-israel-blocks-il-homepage-mobile-app .content {
      text-align: center;
    }

    .mobile-apps {
      margin-bottom: 30px;

      a {
        width: 50%;
        padding: 15px 20px;
        //display: block;
        margin: 0 10px;
        justify-content: center;

        svg {
          display: none;
        }

        .content {
          padding: 0;

          .title {
            @include x-rem("font-size", 35px);
          }
          .svg {
            display: flex;
            //width: 40%;
            max-width: 70%;
            justify-content: center;
            margin: auto;
            margin-bottom: 10px;

            svg {
              width: 100%;
              display: block;
            }
          }

          .desc {
            @include x-rem("font-size", 20px);
            line-height: 26px;
          }
        }

        &:first-child {
          //margin-bottom: 20px;
        }
      }
    }

    .content-title {
      h1 {
        @include x-rem("font-size", 30px);
        line-height: 30px;
      }
    }

    .square-blocks {
      padding: 20px 0 30px;

      .field-name-field-wngv-home-square-blocks {
        & > .field-items {
          display: block;

          & > .field-item {
            .square-block {
              margin: 0 auto;
              margin-bottom: 20px;
              border: none;
              .inner {
                border: 2px solid $blue;
                border-radius: 20px;
                h2 a {
                  @include x-rem("font-size", 26px);
                  line-height: 28px;
                }
              }
            }
          }
        }
      }
    }

    //.drops-blocks {
    //
    //  .field-name-field-title-display {
    //    line-height: 30px;
    //    @include x-rem('font-size', 28px);
    //  }
    //
    //  .field-name-field-description {
    //    width: auto;
    //    margin: 0 0 20px;
    //  }
    //
    //  .drops-list {
    //  }
    //}

    .drops-list {
      .titles {
        .slick-slide {
          .item {
            svg {
              width: 132px;
              height: 169px;
            }
            span {
              top: 95px;
            }
          }
        }
      }
    }

    .blog {
      display: block;
      border: none;
      .blog-image {
        display: flex;
        justify-content: center;
        img {
          border-radius: 18px 18px 0 0;
        }
      }
      margin-bottom: 20px;
    }
  }
}

@media only screen and (max-width: $size-small-1) {
  .node-type-ingv-homepage {
    .mobile-apps {
      a {
        padding: 15px 10px;
      }
    }

    .drops-list {
      margin-top: 0 !important;

      .titles {
        width: 100% !important;
        max-height: 220px;

        .slick-track {
          padding-top: 10px;

          .slick-slide {
            div {
              transform: translate(0, 0) scale(0.9);
              transition: all 0.4s ease;
              opacity: 0.5;
            }
          }

          .slick-slide.slick-center div {
            z-index: 1;
            transform: scale(1.01);
            opacity: 1;
          }
        }
      }
      .descriptions {
        .item {
          p {
            @include x-rem("font-size", 21px);
            @include x-rem("line-height", 22px);
          }
        }
      }
    }
  }
}
