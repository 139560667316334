.node-type-ingv-contact-us {

  .drops-no-slider {
    margin-top: -120px;
    .titles {
      width: 80%;
      margin: auto;
      position: relative;
      cursor: pointer;
      display: flex;
      justify-content: center;

      .item {
        text-align: center;
        position: relative;
        margin: auto;

        svg {
          fill: white;
          stroke: #42CBF9;
          stroke-width: 3px;
          width: 162px;
          height: 199px;
          margin: auto;
        }
        span {
          position: absolute;
          top: 110px;
          right: 0;
          left: 0;
          margin-left: auto;
          margin-right: auto;
          display: block;
          text-align: center;
          width: 130px;
          padding: 0 5px;
          max-height: 40px;
          overflow: hidden;
          line-height: 20px;
          font-size: 24px;
          color: #1AB2E6;
          @include font-style('FbSpacer');
        }
      }

      .item.active {
        svg {
          fill: #42CBF9;
        }
        span {
          color: white;
        }
      }


    }
    .descriptions {
      margin-top: 30px;
      padding: 10px;
      border-radius: 20px;
      box-shadow: 0 0 20px -12px #000;
      .item {
        text-align: right;
        display:none;
        p {
          line-height: 20px;
          @include x-rem('font-size', 22px);
          //color: $il_blue;
          @include font-style('FbSpacer');

          a {
            color: $blue_light;
            &:hover {
              text-decoration: underline;
            }
          }

        }
      }
    }
  }


  .infos {
    margin-top: 50px;

    .container {
      display: flex;
      justify-content: center;

      .col-infos {
        text-align: center;
        width: 33%;
        @include x-rem('margin', 0 12px);
        //border-radius: 20px;
        //box-shadow: 0 0 20px -12px #000;
        padding: 12px;
        h2 {
          @include x-rem('font-size', 25px);
        }
        a {
            color: $blue_light;
            &:hover {
              text-decoration: underline;
            }
        }
      }
    }
  }
}
