section#main-content {
  position: relative;
}

#toolbar {
  direction: ltr;
}

.tabs {
  position: absolute;
  top: 0;
  z-index: 3;
  width: 100%;

  &.tabs-admin {
    ul {
      margin: 0 225px 0 0;
      border: 0;
      padding: 0;

      li {
        a {
          border-radius: 0 0 10px 10px;
          border-top: none;
          border-bottom: 1px solid $blue;
        }
      }
    }
  }
}

body.toolbar.toolbar-drawer {
  header {
    #block-edensprings-israel-blocks-israel-header {
      #header-content {
        .wrapper {
          .right {
            a.home-link {
              top: 64px;
            }
          }
        }
      }
    }
  }
}

body.toolbar {
  header {
    #block-edensprings-israel-blocks-israel-header {
      #header-content {
        .wrapper {
          .right {
            a.home-link {
              top: 30px;
            }
          }
        }
      }
    }
  }
}


body.toolbar.toolbar-drawer {
  header {
    #block-edensprings-israel-blocks-israel-header {
      #header-content {
        .wrapper {
          .right {
            .logo-sticky {
              a.home-link {
                top: 0;
              }
            }
          }
        }
      }
    }
  }
}

body.toolbar {
  header {
    #block-edensprings-israel-blocks-israel-header {
      #header-content {
        .wrapper {
          .right {
            .logo-sticky {
              a.home-link {
                top: 0;
              }
            }
          }
        }
      }
    }
  }
}
