.page-catalog {

  #catalog-shortcuts {
    display: flex;
    @include x-rem('margin', 0 0 30px);
    @include x-rem('font-size', 14px);
    line-height: 16px;

    & > a {
      flex: 0 0 25%;

      &:last-child {
        & > div {
          margin: 0;
        }
      }


      & > div {
        height: 100%;
        display: flex;
        align-items: center;
        @include x-rem('padding', 15px 30px);
        @include x-rem('margin-right', 15px);
        border-bottom: 2px solid $grey_disabled;
        color: $grey_disabled;
        @include box-shadow-content();

        div.svg-icon {
          flex: 0 0 auto;
          margin-right: 8px;
        }

        .svg-icon {
          svg {
            fill: $grey_disabled;
          }
        }

        .filter-content {
          h3 {
            @include x-rem('font-size', 15px);
            line-height: 18px;
            @include x-rem('margin', 0 0 5px);
            font-weight: 500;
            letter-spacing: normal;
          }
        }

        &.active {
          color: $grey;
          border-color: $blue_light;
          position: relative;

          .svg-icon {
            svg {
              fill: $blue_light;
            }
          }

          .filter-content {
            h3 {
              color: $blue_light;
            }
          }

          &:after {
            content: '';
            width: 0;
            height: 0;
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            border-top: 5px solid $blue_light;
            position: absolute;
            bottom: -7px;
            left: 0;
            right: 0;
            margin: 0 auto;
          }
        }
      }
    }
  }

  // Summary filters.
  a#clear-filters {
    float: right;
    display: block;
    background: none;
    color: $blue_grey;
    font-weight: 500;
    @include x-rem('font-size', 12px);
    border: none;
    cursor: pointer;
    padding: 10px 0 0;
    text-transform: uppercase;

    span,
    i {
      vertical-align: middle;
    }

    &:hover {
      color: $grey;
    }
  }

  .sortby {
    text-align: left;
    @include x-rem('margin-bottom', 15px);

    select {
      @include x-rem('width', 200px);
      color: $grey;
      @include x-rem('font-size', 13px);
      @include x-rem('letter-spacing', 1.5px);
      @include x-rem('padding', 0 20px 5px 0);
      border: 0;
      border-bottom: 1px solid $grey_line;
      line-height: normal;
    }
  }

  .products-container {
    display: flex;

    .product-left-filter {
      flex: 0 0 285px;
      max-width: 285px;
      @include x-rem('margin-left', 15px);

      // List of categories.
      .views-filters {
        .views-exposed-form {
          .views-exposed-widgets {
            margin: 0;

            .views-exposed-widget {
              float: none;
              padding: 0;

              & > label {
                color: $blue;
                @include x-rem('font-size', 20px);
                font-weight: bold;
              }

              .form-item {
                padding: 2px 0;
              }

              ul.bef-tree {
                margin: 0;
                padding: 0;

                ul.bef-tree-child {
                  @include x-rem('padding-left', 20px);
                  margin: 0;
                }
              }

              &#checkboxes-wrapper {
                .views-widget {
                  margin-top: 10px;
                  padding-top: 10px;
                  border-top: 1px solid $grey_disabled;
                }
              }
            }
          }
        }

        /* Base for label styling */
        [type="checkbox"]:not(:checked),
        [type="checkbox"]:checked,
        [type="checkbox"].checked {
          position: absolute;
          left: -9999px;
          visibility: hidden;
        }
        [type="checkbox"]:not(:checked) + label,
        [type="checkbox"]:checked + label,
        [type="checkbox"].checked + label {
          @include x-rem('font-size', 13px);
          font-weight: 400;
          position: relative;
          @include x-rem('margin-right', 20px);
          cursor: pointer;
        }

        /* checkbox aspect */
        [type="checkbox"]:not(:checked) + label:before,
        [type="checkbox"]:checked + label:before,
        [type="checkbox"].checked + label:before {
          content: '';
          position: absolute;
          //left: 0;
          top: 0;
          right: -20px;
          width: 15px;
          height: 15px;
          border: 2px solid $grey_disabled;
          background: #fff;
        }

        [type="checkbox"]:checked + label:before,
        [type="checkbox"].checked + label:before {
          border-color: $blue_light;
        }


        /* checked mark aspect */
        [type="checkbox"]:not(:checked) + label:after,
        [type="checkbox"]:checked + label:after,
        [type="checkbox"].checked + label:after {
          content: '';
          position: absolute;
          top: 4px;
          //left: 4px;
          right: -16px;
          width: 7px;
          height: 7px;
          background: $blue_light;
          transition: all .2s;
        }
        /* checked mark aspect changes */
        [type="checkbox"]:not(:checked) + label:after {
          opacity: 0;
          transform: scale(0);
        }
        [type="checkbox"]:checked + label:after,
        [type="checkbox"].checked + label:after {
          opacity: 1;
          transform: scale(1);
        }

        [type="checkbox"]:disabled {
          color: $grey_disabled;
        }

        [type="checkbox"]:disabled + label {
          cursor: auto;
        }

        [type="checkbox"]:disabled + label:after {
          background: $grey_disabled;
        }

        [type="checkbox"]:disabled + label:before {
          border-color: $grey_disabled;
        }

        /* hover style just for information */
        label:hover:before {
          border: 2px solid $blue_light!important;
        }

        [type="checkbox"]:disabled + label:hover:before {
          border: 2px solid $grey_disabled!important;
        }
      }
    }
    .product-left-filter.container-bs {
      border-radius: 20px;
      border: 1px solid $il_blue;
      padding: 0;

      // List of categories.
      .views-filters {
        .views-exposed-form {
          .views-exposed-widgets {
            .views-exposed-widget {

              & > label {
                color: $white;
                text-align: center;
                background: $il_blue;
                border-radius: 20px 20px 0 0;
                @include x-rem('font-size', 25px);
                padding: 10px;
              }

              .views-widget {
                @include x-rem('padding-right', 15px);
              }

              .form-item {

              }

              ul.bef-tree {

                ul.bef-tree-child {

                }
              }

              &#checkboxes-wrapper {
                .views-widget {

                }
              }
            }
          }
        }

        /* Base for label styling */
        [type="checkbox"]:not(:checked),
        [type="checkbox"]:checked,
        [type="checkbox"].checked {

        }
        [type="checkbox"]:not(:checked) + label,
        [type="checkbox"]:checked + label,
        [type="checkbox"].checked + label {
          @include x-rem('font-size', 16px);
          @include font-style('FbSpacer');
        }

        /* checkbox aspect */
        [type="checkbox"]:not(:checked) + label:before,
        [type="checkbox"]:checked + label:before,
        [type="checkbox"].checked + label:before {

        }

        [type="checkbox"]:checked + label:before,
        [type="checkbox"].checked + label:before {

        }


        /* checked mark aspect */
        [type="checkbox"]:not(:checked) + label:after,
        [type="checkbox"]:checked + label:after,
        [type="checkbox"].checked + label:after {

        }
        /* checked mark aspect changes */
        [type="checkbox"]:not(:checked) + label:after {

        }
        [type="checkbox"]:checked + label:after,
        [type="checkbox"].checked + label:after {

        }

        [type="checkbox"]:disabled {

        }

        [type="checkbox"]:disabled + label {

        }

        [type="checkbox"]:disabled + label:after {

        }

        [type="checkbox"]:disabled + label:before {

        }

        /* hover style just for information */
        label:hover:before {

        }

        [type="checkbox"]:disabled + label:hover:before {

        }
      }

      //.views-exposed-form {
      //  #edit-tid-wrapper {
      //    & > label {
      //      color: $white;
      //      text-align: center;
      //      background: $il_blue;
      //      border-radius: 20px 20px 0 0;
      //      @include x-rem('font-size', 25px);
      //      padding: 10px;
      //    }
      //    .views-widget {
      //      @include x-rem('padding-right', 15px);
      //    }
      //  }
      //}
    }

    .product-list {
      max-width: 100%;
      width: 100%;

      .view-content {
        display: flex;
        flex-wrap: wrap;

        .views-row {
          //border-radius: 20px;
          //border: solid 1px $il_blue;

          //@include x-rem('padding', 20px 10px 20px 10px);
          @include x-rem('margin-top', 15px);
          flex-shrink: 1; // Separate flex properties for IE11
          flex-grow: 0;
          flex-basis: calc(33% - 8px);
          max-width: calc(33% - 8px); // HACK IE11

          // All 3 elements starting from 2.
          &:nth-child(3n+2) {
            @include x-rem('margin-left', 15px);
            @include x-rem('margin-right', 15px);
          }

          // First thee elements.
          &:nth-child(-n+3) {
            margin-top: 0;
          }

          article.node-wngv-web-product {
            border-radius: 20px;
            border: solid 1px $il_blue;
            //box-shadow: none;
            transform: none;
            opacity: 1;
            transition: none;
            padding: 0;
          }
        }
        .container-bs {
          padding: 0;
          box-shadow: none;
        }
      }
    }
  }
}
