*:focus {
  outline: none;
}


.form-item {
  padding: 10px 0;
  margin: 0;
  position: relative;

  input.error,
  textarea.error,
  select.error {
    border: 2px solid $red;
  }

  &.required {
    &::before {
      content: '*';
      position: absolute;
      top: 12px;
      left: 2px;
      color: $red;
    }
  }
}

input {
  color: $il_blue;
}

select {
  background: url(../images/arrow.svg) no-repeat 5px;
}

.confirm-parent,
.password-parent {
  width: auto;
}

input[type="text"],
input[type="email"],
input[type="password"],
select {
  border-radius: 10px;
  padding: 10px;
  border: 2px solid $grey_value;
  width: 100%;
  box-shadow: 0 0 20px -12px #000;


  &:not(:placeholder-shown) {
    border-color: $il_blue;
    color: $il_blue;
    font-weight: 400;
    &.error{
      border-color:#E61F2B;
    }
  }

  &:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 1000px white inset;
    -webkit-text-fill-color: $blue_light!important;
  }

  &::-webkit-input-placeholder,
  &::-moz-placeholder,
  &:-ms-input-placeholder {
    color: $grey_value;
  }
}


input[type="submit"] {
  color: $white;
  background-color: $il_blue;
  border-radius: 0 0 10px 10px;
  font-size: 25px;
  padding: 10px;
  border: none;
  cursor: pointer;
  transition: all 0.5s ease-out;

  &:hover {
    background: $blue;
    color: #fff;
  }
}

.form-actions {
  text-align: left;
}

.btn {
  @include x-rem('font-size', 20px);
  font-weight: 500;
  line-height: 19px;
  padding: 10px 30px;
  letter-spacing: 1.5px;
  color: $white;
  background-color: $il_blue;
  border-radius: 15px 15px 0 0;
  @include font-style('FbSpacer', 800);
  display: inline-block;
}
