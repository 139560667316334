.page-gaq {
  h1 {
    width: 40%;
    text-align: center;
    @include font-style("FbSpacer", 800);
    float: left;
    padding: 0;

    &:after {
      clear: both;
      display: block;
      overflow: hidden;
    }
  }

  #block-edensprings-website-ng-commonform-website-ng-commonform {
    overflow: hidden;
    clear: both;

    .webform-wrapper {
      width: 40%;
      margin: auto;
      box-shadow: none;
      float: left;

      .webform-component-checkboxes {
        .form-item {
          padding: 0;
        }

        input[type="checkbox"] {
          position: absolute;
          right: -9999px;

          & + label {
            display: block;
            line-height: 16px;
            cursor: pointer;
            color: $il_blue;
            padding-right: 20px;
            height: auto;
            white-space: normal;
            text-overflow: unset;

            &:before {
              content: "";
              position: absolute;
              top: 0;
              right: 0;
              display: block;
              width: 16px;
              height: 16px;
              border: 2px solid $grey_value;
            }
          }

          &:checked {
            & + label {
              color: $il_blue;

              &:before {
                border: 2px solid $il_blue;
              }

              &:after {
                content: "";
                position: absolute;
                top: 4px;
                right: 4px;
                display: block;
                width: 8px;
                height: 8px;
                background: $il_blue;
              }
            }
          }
        }
      }

      .form-type-checkbox {
        margin: 0;
      }
    }
  }

  .webform-list {
    .phone {
      display: block;
      width: 150px;
      text-align: center;
      background-color: $blue;
      border-radius: 10px;
      padding: 8px 12px;
      @include x-rem("font-size", 22px);
      font-weight: bold;
      margin: 0 auto 15px auto;
      & > a {
        color: $white;
        & > i {
          vertical-align: middle;
          margin-top: -3px;
          @include x-rem("font-size", 22px);
        }
        span {
          display: inline-block;
          vertical-align: sub;
          line-height: 0;
        }
      }
    }
  }
}

.node-type-webform {
  article {
    max-width: 1200px;
    margin: auto;
  }
}
