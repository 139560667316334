// TABLET < 1024
@media only screen and (max-width: $size-medium-large-1){
  body.sticky {
    header {
      #block-edensprings-israel-blocks-israel-header {
        #header-content {
          .wrapper {
            .right {
              .logo-sticky {
                a.home-link {
                  top: 5px;
                  img {
                    width: 50px;
                  }
                }
              }
            }
            .left {
              .top-header {

              }
              .menu {

              }
            }
          }
        }
      }
    }
  }
}

// MOBILE < 768
@media only screen and (max-width: $size-medium-1){
  body.page-gaq {

    .page {
      background-position: left 0;

    }
  }

  body.no-main-banner .page {
    margin-top: 0;
  }
}
