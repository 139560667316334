// MOBILE < 768
@media only screen and (max-width: $size-medium-1){
  .node-type-wngv-basic-page {
    .field-name-body {
      width: 100%;
    }

    .drops {
      margin-top: 0;
      .slick-slide {

        .drop {

          svg {
            fill: white;
            stroke: #42CBF9;
            stroke-width: 3px;
            width: 72px;
            height: 109px;
            margin: auto;
          }
          span {
            top: 50px;
            width: 70px;
            @include x-rem('font-size', 18px);
            a {

            }

          }
        }
        .drop.active {
          svg {

          }
          span {
            a {

            }
          }
        }
      }
    }
  }
}

// Mobile < 490
@media only screen and (max-width: $size-small-1){
  .node-type-wngv-basic-page {
    .drops {

      .slick-slide {
        div {
          transform: translate(0, 0) scale(.7);
          transition: all .4s ease;
          opacity: .5;

          .drop {
            svg {
              height: auto !important;
              width: auto !important;
            }
            span {
              top: 50px;
              a {

              }

            }
          }
          .drop.active {
            svg {

            }
            span {
              a {

              }
            }
          }
        }
      }
      .slick-slide.lt2 div {
        display: none;
        transform: translate(10%, 0) scale(.8);
      }
      .slick-slide.lt1 div {
        opacity: .8;
        transform: translate(5%, 0) scale(.9);
      }
      .slick-slide.gt1 div {
        opacity: .8;
        transform: translate(-5%, 0) scale(.9);
      }
      .slick-slide.gt2 div {
        display: none;
        transform: translate(-10%, 0) scale(.8);
      }
      .slick-slide.slick-center div {
        z-index: 1;
        transform: scale(1.04);
        opacity: 1;
      }
    }
  }
}