.textvsimage {
  display: flex;
  justify-content: normal;
  margin-bottom: 20px;
  @include box-shadow-content();
  padding: 20px;
  align-items: center;
  background: $white;

  .field-name-field-wngh-tvi-image {
    flex: 0 0 485px;

    img {
      display: block;
      margin: auto;
    }
  }

  .content {
    padding: 10px 30px;
    flex-grow: 1;

    .field-name-field-wngh-tvi-description {
      margin-bottom: 40px;
    }

    h2 {
      a {
        color: $blue;
        @include x-rem('font-size', 28px);
        line-height: 36px;
        letter-spacing: 2px;
        margin: 0;
        margin-bottom: 10px;
      }
    }
    
    a.btn-vs {
      @include x-rem('font-size', 14px);
      line-height: 19px;
      font-weight: 500;
      border-radius: 30px;
      float: right;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 12px 30px;
      color: $white;
      background: $blue;
      transition: color 0.5s ease, background-color 0.5s ease, border-color 0.5s ease;

      span {
        display: inline-block;
      }

      svg {
        margin-left: 5px;
        width: 35px;
        height: 35px;
        fill: $white;
        transition: fill 0.5s ease;
      }

      &:hover {
        background: $blue_light;
      }

    }
  }
}

// Text VS Image (Page)
.node-type-wngv-tvip {
  .introduction {
    text-align: center;

    .field-name-field-wngv-tvip-subtitle {
      font-weight: 700;
      @include x-rem('font-size', 30px);
      @include x-rem('letter-spacing', 1.5px);
      @include x-rem('line-height', 32px);
      color: $blue;
      margin-bottom: 15px;
    }

    .field-name-field-wngv-tvip-sub-description {
      margin-bottom: 30px;

      &:after {
        content: '';
        margin: 30px auto 0;
        width: 2px;
        background: $blue_light;
        height: 60px;
        display: block;
      }
    }
  }

  .field-name-field-illimited-text-vs-image {
    & > .field-items {
      & > .field-item:nth-child(odd) {
        .textvsimage {
          flex-direction: row-reverse;
        }
      }
    }
  }

  .drops {
    margin-top: -120px;
    margin-bottom: 70px;

    .slick-slide {
      position: relative;
      cursor: pointer;
      .drop {
        text-align: center;
        transition: transform 0.8s;
        &:hover {
          &:hover {
            transform: translate(0px, 8px);
          }
        }
        svg {
          fill: white;
          stroke: #42CBF9;
          stroke-width: 3px;
          width: 132px;
          height: 169px;
          margin: auto;
        }
        span {
          position: absolute;
          top: 95px;
          right: 0;
          left: 0;
          margin-left: auto;
          margin-right: auto;
          display: block;
          text-align: center;
          width: 130px;
          padding: 0 5px;
          max-height: 56px;
          overflow: hidden;
          line-height: 20px;
          font-size: 24px;
          a {
            color: $il_blue;
            @include font-style('FbSpacer');
          }

        }
      }
      .drop.active {
        svg {
          fill: #42CBF9;
        }
        span {
          a {
            color: white;
          }
        }
      }
    }
  }
}

// ISRAEL
html[dir="rtl"] {
  .textvsimage {
    .content {
      a.btn-vs {
        float: left;
      }
    }
  }
}
