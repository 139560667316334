.node-type-ingv-business-line {

  .drops-or-beans {
    margin-top: -120px;
    .titles {
      width: 80%;
      margin: auto;

      .slick-slide {
        position: relative;
        cursor: pointer;

        .item {
          text-align: center;
          transition: transform 0.8s;
          padding-bottom: 5px;

          &:hover {
            &:hover {
              transform: translate(0px, 8px);
            }
          }
          svg {
            fill: white;
            stroke: #42CBF9;
            stroke-width: 3px;
            width: 162px;
            height: 199px;
            margin: auto;
          }
          span {
            position: absolute;
            bottom: 55px;
            right: 0;
            left: 0;
            margin-left: auto;
            margin-right: auto;
            display: block;
            text-align: center;
            width: 45%;
            padding: 0 5px;
            max-height: 40px;
            overflow: hidden;
            line-height: 20px;
            font-size: 24px;
            color: #1AB2E6;
            word-wrap: normal;
            @include font-style('FbSpacer');
          }
        }
      }

      .slick-slide.slick-current {
        .item {
          svg {
            fill: #42CBF9;
          }
          span {
            color: white;
          }
        }
      }

    }
    .descriptions {
      text-align: center;
      width: 80%;
      margin: auto;
      .item {
        p {
          line-height: 20px;
          @include x-rem('font-size', 25px);
          color: $il_blue;
          @include font-style('FbSpacer');
        }
      }
    }
  }


  .drops-or-beans.coffee {
    margin-top: -180px;
    .titles {
      .slick-slide {
        margin: 0 30px;

        .item {
          svg {
            stroke: none;
            width: 100%;
            height: 100%;
            //transform: rotate(70deg);
          }
          span {
            @include x-rem('font-size', 40px);
            color: $white;
            top: 39%;
            bottom: auto;
            left: 6%;
            right: auto;
            width: 66%;
            line-height: 50px;
            max-height: 100px;
          }
        }
      }
      .slick-slide.slick-current {
        .item {
          svg {
          }
          span {
            color: $white;
          }
        }
      }
    }
    .descriptions {
      text-align: center;
      width: 80%;
      margin: auto;
      .slick-slide {
        .item {
          p {
            line-height: 20px;
            @include x-rem('font-size', 25px);
            color: $brown;
            @include font-style('FbSpacer');
          }
        }
      }
    }
  }


  // List of products
  .field-name-field-wgnv-bl-web-products {

    .slick-slider {
      padding: 0 35px;

      .slick-track {
        display: flex;
        align-items: stretch;
        justify-content: center;
        padding: 10px 5px;

        .slick-slide {
          height: auto;
          display: flex;
          justify-content: center;
          margin: 0 35px;
          //margin-left: 70px;

          div {
            width: 100%;

            .field-item {
              height: 100%;
              article {
                position: relative;
                border: solid 2px $il_blue;
                border-radius: 20px;
                //width: 80%;
                box-shadow: 0 0 20px -12px #000;
                padding: 0;
                overflow: hidden;
              }
            }
          }
        }
        &:last-child {
          margin: 0;
        }
      }

      .slick-arrow {
        position: absolute;
        //top: calc(50% - 42px);
        top: 50%;
        transform: translateY(-50%);
        color: $blue;
        font-weight: bold;
        cursor: pointer;
        z-index: 3;

        &.slick-arrow-prev {
          right: -5px;
        }

        &.slick-arrow-next {
          left: -5px;
        }

        &:hover {
          color: $blue_light;
        }
      }
    }
  }

  // Square blocks
  .square-blocks {

    h2 {
      text-align: center;
    }

    .field-collection-container {
      width: 75%;
      margin: auto;
    }

    .field-name-field-wngv-home-square-blocks {
      & > .field-items {
        display: flex;
        justify-content: space-around;
        align-items: center;

        & > .field-item {

          .square-block {
            //max-width: 560px;
            max-width: 350px;
            margin: 0 20px;
            position: relative;
            text-align: center;
            border: solid 2px $il_blue;
            box-shadow: 0px 0px 20px -12px #000;
            border-radius: 20px;

            img {
              display: block;
              border-radius: 20px;
            }

            .inner {
              position: absolute;
              top: 0;
              left: 0;
              right: 0;
              display: flex;
              flex-direction: column;
              justify-content: center;
              padding: 25px;
              height: 100%;
              align-items: center;
              margin: auto;

              h2 {
                margin-top: 0;
              }

              h2 a {
                color: $il_blue;
                @include x-rem('font-size', 34px);
                line-height: 36px;
                letter-spacing: 2px;
                margin: 0 0 15px;
                font-weight: 900;
                max-width: 100%; // IE11
              }

              .field-name-field-fc-square-desc {
                max-width: 75%;
                margin-bottom: 50px;
                @include x-rem('font-size', 22px);
                line-height: 20px;
                color: $il_blue;
              }

              span.btn,
              a.btn {
                bottom: 0;
                position: absolute;
                color: $white;
              }
            }
          }
        }
      }
    }
  }




  //------------------------- COFFEE OVERRIDE ---------------------------//

  .coffee {
    .field-name-field-wgnv-bl-web-products {
      .slick-slider {
        .slick-track {
          .slick-slide {
            div {
              .field-item {
                article {
                  border: solid 2px $brown;
                  border-radius: 20px;

                  h4 {
                    //margin-bottom: 0;
                    a {
                      color: $brown;
                    }
                  }

                  .more-details {
                    a.btn {
                      background-color: $brown;
                    }
                    a.btn:hover {
                      color: $white;
                    }
                  }
                }
              }
            }
          }
        }

        .slick-arrow {
          color: $brown;

          &:hover {
            color: $brown_light;
          }
        }
      }
    }
  }

  .square-blocks.coffee {
    h2 {
      color: $brown;
    }
    .field-name-field-wngv-home-square-blocks {
      & > .field-items {
        & > .field-item {
          .square-block {
            border: solid 2px $brown;
            .inner {
              h2 a {
                color: $brown;
              }
              .field-name-field-fc-square-desc {
                color: $brown;
              }
              span.btn,
              a.btn {
                background: $brown;
              }
            }
          }
        }
      }
    }
  }
}
