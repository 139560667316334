// < 1200
@media only screen and (max-width: $size-large) {
  .node-type-ingv-business-line {
    .drops-or-beans.coffee {
      .titles {
        .slick-slide {
          .item {
            span {
              @include x-rem("font-size", 32px);
              line-height: 32px;
            }
          }
        }
      }
    }
  }
}

// MOBILE < 1024
@media only screen and (max-width: $size-medium-large-1) {
  .node-type-ingv-business-line {
    .drops-or-beans.coffee {
      .titles {
        .slick-slide {
          margin: 0 10px;

          .item {
            span {
              @include x-rem("font-size", 30px);
              line-height: 30px;
              max-height: 95px;
            }
          }
        }
      }
      .descriptions {
        width: 100%;
      }
    }
  }
}

// MOBILE < 768
@media only screen and (max-width: $size-medium-1) {
  .node-type-ingv-business-line {
    .drops-or-beans {
      margin-top: 0;

      .titles {
        .slick-slide {
          .item {
            span {
            }
          }
        }
      }
    }

    .drops-or-beans.coffee {
      margin-top: 0;

      .titles {
        .slick-slide {
          width: 200px;
          .item {
            span {
              @include x-rem("font-size", 24px);
              line-height: 30px;
              left: 8%;
            }
          }
        }
      }
    }

    // List of products
    .field-name-field-wgnv-bl-web-products {
      .slick-slider {
        padding: 0 40px;

        .slick-track {
          .slick-slide {
            margin-left: 15px;
            margin-right: 15px;
          }
        }

        .slick-arrow {
          &.slick-arrow-prev {
            right: 10px !important;
          }
          &.slick-arrow-next {
            left: 0 !important;
          }
          &:hover {
            color: $il_blue !important;
          }
        }
      }
    }

    .field-name-field-wgnv-bl-web-products.coffee {
      .slick-slider {
        .slick-arrow {
          &:hover {
            color: $brown !important;
          }
        }
      }
    }

    .field-name-field-wgnv-bl-web-products.coffee {
      .slick-slider {
        .slick-arrow {
          &:hover {
            color: $brown !important;
          }
        }
      }
    }

    // Square blocks
    .square-blocks,
    .square-blocks.coffee {
      .field-collection-container {
        //width: 80% !important;
      }

      .field-name-field-wngv-home-square-blocks {
        & > .field-items {
          display: block !important;

          & > .field-item {
            margin-bottom: 20px;
            .square-block {
              margin: auto;
            }
          }
        }
      }
    }
  }
}

// Mobile < 490
@media only screen and (max-width: $size-small-1) {
  .node-type-ingv-business-line {
    .drops-or-beans {
      margin-top: 0 !important;

      .titles {
        width: 100% !important;
        max-height: 220px;

        .slick-track {
          padding-top: 10px;

          .slick-slide {
            div {
              transform: translate(0, 0) scale(0.9);
              transition: all 0.4s ease;
              opacity: 0.5;
            }

            .item {
              span {
                width: 60%;
              }
            }
          }

          .slick-slide.slick-center div {
            z-index: 1;
            transform: scale(1.01);
            opacity: 1;
          }
        }
      }
      .descriptions {
        .item {
          p {
            @include x-rem("font-size", 21px);
            @include x-rem("line-height", 22px);
          }
        }
      }
    }

    .drops-or-beans.coffee {
      margin-top: 0 !important;

      .titles {
        max-height: 220px;

        .slick-slide {
          margin: 0 5px;
          div {
            transform: translate(0, 0) scale(0.9);
            transition: all 0.4s ease;
            opacity: 0.5;
          }
        }

        .slick-slide.slick-center div {
          z-index: 1;
          transform: scale(1.01);
          opacity: 1;
        }
      }
      .descriptions {
        text-align: center;
        .slick-slide {
          .item {
            p {
              @include x-rem("font-size", 21px);
              @include x-rem("line-height", 22px);
            }
          }
        }
      }
    }

    // Square blocks
    .square-blocks,
    .square-blocks.coffee {
      .field-collection-container {
        width: 100% !important;
      }

      .field-name-field-wngv-home-square-blocks {
        & > .field-items {
          display: block !important;

          & > .field-item {
            margin-bottom: 20px;
          }
        }
      }
    }
  }
}
