// TABLET < 1024
@media only screen and (max-width: $size-medium-large-1){
  .node-type-blog {
    .related-post {
      @include x-rem('padding-left', 10px);
      @include x-rem('padding-right', 10px);

      .field-name-field-blog-related-post {
        & > .field-items {
          flex-wrap: wrap;
          justify-content: flex-start;

          & > .field-item {
            flex: 0 0 calc(50% - 5px);
            max-width: calc(50% - 5px); // HACK IE11
            @include x-rem('margin-right', 10px);
            @include x-rem('margin-bottom', 10px);

            &:last-child {
              @include x-rem('margin-right', 10px);
              @include x-rem('margin-bottom', 10px);
            }

            &:nth-child(2n) {
              margin-right: 0;
            }
          }
        }
      }
    }
  }
}

// MOBILE < 768
@media only screen and (max-width: $size-medium-1){
  //.view-wng-blogs {
  //  display: block;
  //
  //  .view-filters {
  //    display: none;
  //  }
  //
  //  .view-content {
  //    .views-row {
  //      .blog {
  //        display: block;
  //        text-align: center;
  //
  //        .blog-image {
  //          margin-right: 0;
  //        }
  //
  //        .blog-infos {
  //          .more-details {
  //            float: none;
  //          }
  //        }
  //      }
  //    }
  //  }
  //
  //  .item-list {
  //    .pager {
  //      li.pager-first,
  //      li.pager-last {
  //        display: none;
  //      }
  //
  //      li.pager-previous,
  //      li.pager-next {
  //        display: block;
  //      }
  //    }
  //  }
  //}

  .node-type-blog {
    .content.container {
      box-shadow: none;
      padding: 0 10px;

      .tags {
        display: block;
        text-align: center;

        .field-name-field-blog-category {
          padding: 0;
          margin-bottom: 15px;

          .field-item:last-child {
            margin-right: 0;
          }
        }
      }

      .body {
        display: flex;
        flex-direction: column-reverse;

        .field-name-body {
          p {
            margin: 0 0 15px;
          }
        }
      }
    }
  }
  .page-blog {
    .content {
      .blog-content {
        .blog {
          .blog-image {
            flex: 1;
            padding: 0;
          }
          .blog-infos {
            .all-categories {
              .one-category {
                @include x-rem('font-size', 18px);
              }
            }
          }
        }
      }
    }
  }


  article.node-blog.node-teaser {
    &.node-teaser {
      h1 {
        @include x-rem('font-size', 14px);
        line-height: 16px;
      }

      div.more-details {
        .btn {
          @include x-rem('padding', 12px 20px);
          @include x-rem('font-size', 11px);
        }
      }
    }
  }

  /**
 * Specific blog_category term page
 */
  .page-blogs-category {

    .container {
      display: block;

      .terms-list {
        margin-bottom: 20px;
        margin-left: 0;
        text-align: center;
        .title {
          align-items: center;
          display: flex;
          justify-content: center;
          i {
            display: flex;
          }
          i.rotate {
            transform: rotate(-90deg);
          }
        }
        .group {
          .term {
            width: 100%;
          }
        }

      }

      .view-blog-lists-specific-term {
        .view-content {
          .views-row {
            flex: 0 0 48%;
            &:nth-child(2n+1) {
              margin-left: 20px;
            }
            .blog {
              margin-bottom: 20px;
              &:nth-child(2n+1) {
                margin-left: 0;
              }
            }
          }
        }
      }
    }


  }
}


// MOBILE < 490
@media only screen and (max-width: $size-small-1){
  .page-blog {
    .blog-content {
      display: block;
      .hottest-blogs {
        margin-bottom: 20px;
        margin-left: 0;
        display: none;
      }
      .highlighted-blog {
        display: block;
        .blog {
          margin-left: 0 !important;
          margin-bottom: 20px;
        }
        &:nth-child(2n+1) {
          margin-left: 0;
        }
      }
    }
  }

/**
 * Specific blog_category term page
 */
  .page-blogs-category {
    .container {
      .view-blog-lists-specific-term {
        .view-content {
          display: block;
          .views-row {
            &:nth-child(2n+1) {
              margin-left: 0;
            }
            .blog {
              margin-bottom: 20px;
              &:nth-child(2n+1) {
                margin-left: 0;
              }
              .blog-infos {
                padding: 10px 10px 0 10px;
                h2 {
                  @include x-rem('font-size', 30px);
                  line-height: 28px;
                }
              }
            }
          }
        }
      }
    }
  }
}

