.node-type-ingv-homepage {

  .mobile-apps {
    display: flex;
    justify-content: space-around;
    margin-bottom: 50px;

    a {
      background-color: $blue;
      display: flex;
      align-items: center;
      width: 540px;
      padding: 20px 30px;
      border-radius: 20px;
      //box-shadow: 0px 0px 20px -12px #000;
      box-shadow: 4px 4px 15px -5px #252D5C;
      margin: 0 15px;

      .content {
        color: white;
        padding-right: 20px;
        line-height: 40px;
        .title {
          @include x-rem('font-size', 45px);
        }
        .svg {
          width: 70%;
        }
        .desc {
          @include x-rem('font-size', 30px);
        }
      }

      svg#smartphone {
        flex: 0 0 100px;
      }
      svg#user-icon {
        flex: 0 0 90px;
      }
    }
  }

  .content-title {
    text-align: center;

    h1 {
      padding: 0;
      color: $blue;
    }
  }

  .square-blocks {
    padding: 50px 0;

    .field-name-field-wngv-home-square-blocks {
      & > .field-items {
        display: flex;
        justify-content: space-around;
        align-items: center;

        & > .field-item {
          //flex: 0 0 50%;


          .square-block {
            //max-width: 560px;
            max-width: 350px;
            margin: 0 20px;
            position: relative;
            text-align: center;
            border: solid 2px $il_blue;
            box-shadow: 0px 0px 20px -12px #000;
            border-radius: 20px;

            img {
              display: block;
              border-radius: 20px;
            }

            .inner {
              position: absolute;
              top: 0;
              left: 0;
              right: 0;
              display: flex;
              flex-direction: column;
              justify-content: center;
              padding: 25px;
              height: 100%;
              align-items: center;
              margin: auto;

              h2 {
                margin-top: 0;
              }

              h2 a {
                color: $il_blue;
                @include x-rem('font-size', 34px);
                line-height: 36px;
                letter-spacing: 2px;
                margin: 0 0 15px;
                font-weight: 900;
                max-width: 100%; // IE11
              }

              .field-name-field-fc-square-desc {
                max-width: 75%;
                margin-bottom: 50px;
                @include x-rem('font-size', 22px);
                line-height: 20px;
                color: $il_blue;
              }

              span.btn,
              a.btn {
                bottom: 0;
                position: absolute;
                color: $white;
              }
            }
          }
        }
      }
    }
  }

  .drops-list {
    margin-top: 50px;
    margin-bottom: 50px;

    //.field-name-field-title-display {
    //  text-align: center;
    //  color: $blue;
    //  @include x-rem('font-size', 34px);
    //  font-weight: bold;
    //  margin-bottom: 20px;
    //  line-height: 30px;
    //  @include font-style('FbSpacer');
    //}
    //
    //.field-name-field-description {
    //  color: $il_blue;
    //  text-align: center;
    //  width: 80%;
    //  margin: 0 auto 70px;
    //  @include x-rem('font-size', 22px);
    //  @include font-style('FbSpacer');
    //}
    //
    //.drops-list {
    //  .slick-slide {
    //    position: relative;
    //    cursor: pointer;
    //    .drop {
    //      text-align: center;
    //      svg {
    //        fill: white;
    //        stroke: #42CBF9;
    //        stroke-width: 3px;
    //        width: 162px;
    //        height: 199px;
    //        margin: auto;
    //      }
    //      span {
    //        position: absolute;
    //        top: 110px;
    //        right: 0;
    //        left: 0;
    //        margin-left: auto;
    //        margin-right: auto;
    //        display: block;
    //        text-align: center;
    //        width: 130px;
    //        padding: 0 5px;
    //        max-height: 40px;
    //        overflow: hidden;
    //        line-height: 20px;
    //        font-size: 24px;
    //        a {
    //          color: $il_blue;
    //          @include font-style('FbSpacer');
    //        }
    //      }
    //    }
    //  }
    //}

    .titles {
      width: 80%;
      margin: auto;

      .slick-slide {
        position: relative;
        cursor: pointer;

        .item {
          text-align: center;
          transition: transform 0.8s;

          &:hover {
            &:hover {
              transform: translate(0px, 8px);
            }
          }

          svg {
            fill: white;
            stroke: $blue;
            stroke-width: 3px;
            width: 162px;
            height: 199px;
            margin: auto;
          }
          span {
            position: absolute;
            top: 110px;
            right: 0;
            left: 0;
            margin-left: auto;
            margin-right: auto;
            display: block;
            text-align: center;
            width: 130px;
            padding: 0 5px;
            max-height: 40px;
            overflow: hidden;
            line-height: 20px;
            font-size: 24px;
            color: $blue;
            @include font-style('FbSpacer');
          }
        }
      }

      .slick-slide.slick-current {
        .item {
          svg {
            fill: $blue;
          }
          span {
            color: white;
          }
        }
      }

    }
    .descriptions {
      text-align: center;
      .item {
        p {
          line-height: 20px;
          @include x-rem('font-size', 25px);
          color: $il_blue;
          @include font-style('FbSpacer');
        }
      }
    }


  }

  .blog {
    display: flex;
    border-radius: 20px;
    border: solid 2px $il_blue;
    box-shadow: 0 0 20px -12px #000;
    width: 80%;
    margin: auto;
    .blog-image {
      flex: 0 0 auto;
      padding: 0 0 0 20px;
      img {
        display: block;
        border-radius: 18px 18px 18px 18px;
        padding: 10px;
      }
    }
    .blog-infos {
      padding: 10px 0 0 10px;
      display: flex;
      flex-direction: column;
      color: $grey_value;
      h2 {
        color: $il_blue;
        margin-bottom: 0;
        @include x-rem('font-size', 26px);
      }
      .field-name-body {
        flex: 1 1 auto;
      }
      .more-details {
        align-self: center;
        a {
          //@include x-rem('font-size', 13px);
          font-weight: 500;
          line-height: 19px;
          padding: 10px 30px;
          letter-spacing: 1.5px;
          color: $white;
          background-color: $il_blue;
          border-radius: 15px 15px 0 0;
          display: block;
        }
      }
    }
  }
}
