.node-type-wngv-basic-page {
  .field-name-body {
    width: 80%;
    margin: auto;
  }

  .drops {
    margin-top: -120px;
    .slick-slide {
      position: relative;
      cursor: pointer;
      .drop {
        text-align: center;
        transition: transform 0.8s;
        &:hover {
          &:hover {
            transform: translate(0px, 8px);
          }
        }
        svg {
          fill: white;
          stroke: #42CBF9;
          stroke-width: 3px;
          width: 132px;
          height: 169px;
          margin: auto;
        }
        span {
          position: absolute;
          top: 95px;
          right: 0;
          left: 0;
          margin-left: auto;
          margin-right: auto;
          display: block;
          text-align: center;
          width: 130px;
          padding: 0 5px;
          max-height: 56px;
          overflow: hidden;
          line-height: 20px;
          font-size: 24px;
          a {
            color: $il_blue;
            @include font-style('FbSpacer');
          }

        }
      }
      .drop.active {
        svg {
          fill: #42CBF9;
        }
        span {
          a {
            color: white;
          }
        }
      }
    }
  }
}
