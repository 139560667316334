// TABLET < 1024
@media only screen and (max-width: $size-large){
  .main-banner {
    .slide-item {
      .content {
        .left {
          margin: 0 60px;
          width: auto;
        }
      }
    }
  }
}

// MOBILE < 768
@media only screen and (max-width: $size-medium-1) {
  .main-banner {
    //margin-bottom: 15px;
    .slide-item {
      img {
        border-radius: 0;
        right: auto;
        margin-right: auto;
        height: auto;
        margin-left: 0;
        max-width: 100%;
      }

      .content {
        position: initial;
        background: $blue_light;
        text-align: center;
        .container {
          height: unset;
          .banner-texts-center,
          .banner-texts-left {
            width: 100%;
            padding: 8px 12px 12px;

            .title {
              color: $white !important; // Needed for inline-css override
              padding-bottom: 10px;
              @include x-rem('font-size', 28px);
              line-height: 26px;
            }

            .cta-links {
              display: block;
              margin-top: 20px;
              left: 25px;
              position: static;
              .cta-link {
                @include x-rem('font-size', 16px);
                line-height: 18px;
                margin: 10px auto;
                border-radius: 15px;
              }
            }

            .field-name-field-wngh-banner-description {
              .field-items {
                .field-item {
                  div {
                    color: $white !important; // Needed for inline-css override
                  }
                }
              }
            }
          }
        }
      }
    }

    .slick-dots {
      top: 370px;
    }
  }
  .main-banner.coffee {
    .slide-item {
      .content {
        background: $brown2_dark;
      }
    }
  }
}

// MOBILE < 490
@media only screen and (max-width: $size-small-1) {
  .main-banner {
    .slide-item {
      .content {
        .banner-texts-center,
        .banner-texts-left {
          margin: 0;

          .title {
            width: 100%;
            @include x-rem('font-size', 26px);
            @include x-rem('letter-spacing', 2px);
            @include x-rem('line-height', 28px);
          }

          .field-name-field-wngh-banner-description {
            @include x-rem('font-size', 18px);
            @include x-rem('line-height', 18px);
          }

        }
      }
    }
  }
  .main-banner.coffee {
    .slide-item {
      .content {
        .left {
          margin: 0;

          .title {
            width: 100%;
            @include x-rem('font-size', 32px);
            @include x-rem('letter-spacing', 2px);
            @include x-rem('line-height', 38px);
          }

          .field-name-field-wngh-banner-description {
            @include x-rem('font-size', 22px);
            @include x-rem('line-height', 22px);
          }

        }
      }
    }
  }
}
